// import Button from 'react-bootstrap/Button';
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { InputTextImporte } from "../../componentes/editar/planilla/InputTextImporte";
// import { InputComboBoxTxtunidadmedida } from "../../componentes/editar/item/InputComboBoxTxtunidadmedida";
// import { InputComboBoxTxtestado } from "../../componentes/editar/item/InputComboBoxTxtestado";
// import { InputComboBoxCategoria } from "../../componentes/editar/item/InputComboBoxCategoria";
// import { InputComboBoxN2TextId } from "../../componentes/editar/InputComboBoxN2TextId";
import { InputText } from "../../componentes/editar/InputText";
import { InputComboBoxTextId } from "../../componentes/agregar/InputComboBoxTextId";
import { Link } from "react-router-dom";
import Toast from 'react-bootstrap/Toast';
import Modal from 'react-bootstrap/Modal';
import  RouterView  from '../../componentes/layout/RouterView';
import { useNavigate } from 'react-router-dom';
import Sidebar from "../Sidebar";
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import '../../Assets/css/din_style_2.css'
import '../../Assets/css/StyleSlidebar.css'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useSelector } from 'react-redux';
import API_BASE_URL  from "../../config";
const nombre_controller = 'Planillas';           // <<<<<< CAMBIO
const url = `${API_BASE_URL}`;       // <<<<<< CAMBIO
const RutaHead = [
    { name: 'Planilla Caja', ruta: `/${nombre_controller}List` },        // <<<<<< CAMBIO
    { name: 'Cierre de Caja', ruta: `/${nombre_controller}Upd` }, // <<<<<< CAMBIO
];
function ItemUpd() {     // <<<<<< CAMBIO
    const { id } = useParams(); 
    const navigate = useNavigate();
    //Redux
    const user = useSelector((state) => state.user);
    const [Idempresa, setIdempresa] = useState(user.companyId);
    const [Idusuario, setIdusuario] = useState(user.userId);
    // Función para actualizar el usuario en el estado local cuando cambie en Redux
    useEffect(() => {
        setIdempresa(user.companyId);
        setIdusuario(user.userId);
    }, [user]); 
    //TOAST
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBackground , setToastBackground ] = useState('#198754');
    const token2 = localStorage.getItem('token-dincors');
    // Agrega un estado para controlar la visibilidad del modal
    const [ShowRecuentoInventario, setShowRecuentoInventario] = useState(false);
    const handleCloseRecuentoInventario = () => setShowRecuentoInventario(false);
    const handleShowRecuentoInventario = () => {
        setShowRecuentoInventario(true);
    };
    // Realizando recuento
    const [RecuentoEfectivo, setRecuentoEfectivo] = useState('0.00');       //decimal // <<<<<< CAMBIO
    const [RecuentoSinEfectivo, setRecuentoSinEfectivo] = useState('0.00');       //decimal // <<<<<< CAMBIO
    const [RecuentoInternas, setRecuentoInternas] = useState('0.00');       //decimal // <<<<<< CAMBIO
    const FnCajaRecuentoInventario = async () => {
        let mensaje='';
        if(RecuentoEfectivo !== '' && RecuentoSinEfectivo !=='' && RecuentoInternas !==''){     // <<<<<< CAMBIO
            const jsonData = {
                Idcajaapertura:id,
                SaldoCierre:DataCajaApertura.saldo_cierre,
                MontoCalculado:DataCajaApertura.monto_calculado,
                MontoContado: DataCajaApertura.monto_contado,
                MontoEfectivo: DataCajaApertura.monto_efectivo,
                MontoSinEfectivo: DataCajaApertura.monto_sin_efectivo,
                MontoInternas: DataCajaApertura.ventas_internas,
                RecuentoEfectivo: RecuentoEfectivo,
                RecuentoSinEfectivo: RecuentoSinEfectivo,
                RecuentoInternas: RecuentoInternas,
                Idempresa: Idempresa,
                Idusuario: Idusuario,
            };
            const token = localStorage.getItem('token-dincors');
            try{
                const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}RecuentoCaja`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(jsonData),
                })
                if(response.ok){
                    const data = await response.json();
                    if(data.res){
                        setDataCajaApertura(data.cajaapertura)
                        setRecuentoEfectivo(data.cajaapertura.monto_efectivo_contado)
                        setRecuentoSinEfectivo(data.cajaapertura.monto_sin_efectivo_contado)
                        setDataKardexCaja(data.kardexcaja);
                        setToastBackground('#198754');
                        setToastMessage(data.msg);
                        setShowToast(true);
                    }else{
                        throw new Error(data.msg);
                    }
                }else {
                    throw new Error(`${response.status} ${response.statusText}`);
                }
            } catch(error) {
                setToastBackground('#CC6868');
                setToastMessage(error.message);
                setShowToast(true);
            }finally{
                handleCloseRecuentoInventario();
            };
        }else{
            mensaje =`Operación detenida. Llene los montos de recuento para realizar el cuadre.`;
            setToastMessage(mensaje);
            setShowToast(true);
            setToastBackground('#CC6868');
            handleCloseRecuentoInventario();
        }
    };
    
    // Agrega un estado para controlar la visibilidad del modal
    const [ShowCierreCaja, setShowCierreCaja] = useState(false);
    const handleCloseCierreCaja = () => setShowCierreCaja(false);
    const handleShowCierreCaja = () => {
        setShowCierreCaja(true);
    };
    // Cierre caja 
    const FnCierreCaja = async () => {
        const jsonData = {
            Idcajaapertura:id,
            Idempresa: Idempresa,
            Idusuario: Idusuario,
        };
        const token = localStorage.getItem('token-dincors');
        try{
            const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}CierreCaja`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            })
            if(response.ok){
                const data = await response.json();
                if(data.res){
                    //setDataCajaApertura(data.cajaapertura);
                    // setToastBackground('#198754');
                    // setToastMessage(data.msg);
                    // setShowToast(true);
                    localStorage.setItem('toastMessage1', data.msg);
                    localStorage.setItem('toastBackground1', '#198754');
                    navigate(`/PlanillasList`);
                }else{
                    throw new Error(data.msg);
                }
            }else {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        } catch(error) {
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        } finally {
            handleCloseCierreCaja();
        };
    };
    // Listado de movimiento caja
    const [DataMovimientoCaja, setDataMovimientoCaja]=useState([]);
    const [DataKardexCaja, setDataKardexCaja]=useState([]);
    const [DataCajaApertura, setDataCajaApertura] = useState([]);
    useEffect(() => {
        const DataPlanillaCaja = async ()=>{
            const token = localStorage.getItem('token-dincors');
            const jsonData = {
                Idcajaapertura:id,
                Idempresa: user.companyId, // <<<<<< CAMBIO
                Codusuact: user.userId,
            };
            try {
                const response = await fetch(`${url}/Planillas/PlanillasUpd`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(jsonData),
                });
                if(response.ok){
                    const data = await response.json();
                    console.log("cierre: ",data)
                    if(data.res){
                        setDataMovimientoCaja(data.movimientocaja);
                        setDataKardexCaja(data.kardexcaja);
                        setDataCajaApertura(data.cajaapertura);
                        setRecuentoEfectivo(data.cajaapertura.monto_efectivo_contado !== null ? data.cajaapertura.monto_efectivo_contado : '0.00')
                        setRecuentoSinEfectivo(data.cajaapertura.monto_sin_efectivo_contado !== null ? data.cajaapertura.monto_sin_efectivo_contado : '0.00')
                        setRecuentoInternas(data.cajaapertura.ventas_internas_contado !== null ? data.cajaapertura.ventas_internas_contado : '0.00');
                    }else{
                        throw new Error(data.msg);
                    }
                }else{
                    throw new Error(`${response.status} ${response.statusText}`);
                }
            }catch (error) {
                setToastBackground('#CC6868');
                setToastMessage(error.message);
                setShowToast(true);
            }
        }
        DataPlanillaCaja();
    }, [id, token2, user]);
    // ====================== MODAL DETALLE ======================
    // Agrega un estado para controlar la visibilidad del modal
    const [showDetalle, setShowDetalle] = useState(false);
    const CloseDetalle = () => setShowDetalle(false);
    const [DataDetalle, setDataDetalle] = useState([]);
    const handleShowDetalle = async (itemId) => {
        setShowDetalle(true);
        const jsonData = {
            Idcajaapertura: itemId,
            Idempresa: Idempresa,
            Idusuario: Idusuario,
        };
        const token = localStorage.getItem('token-dincors');
        try{
        const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Detail`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(jsonData),
        })
        if(response.ok){
            const data = await response.json();
            if(data.res){
                setDataDetalle(data.datos);
            }else{
                throw new Error(data.msg);
            }
        }
        }catch(error){
        setToastBackground('#CC6868');
        setToastMessage(error.message);
        setShowToast(true);
        };
    };
    useEffect(() => {
        const storedMessage = localStorage.getItem('toastMessage1');
        const storedBackground = localStorage.getItem('toastBackground1');
        if (storedMessage && storedBackground) {
            setToastMessage(storedMessage);
            setToastBackground(storedBackground);
            setShowToast(true);
            localStorage.removeItem('toastMessage1');
            localStorage.removeItem('toastBackground1');
        }
    }, []);
    // ====================== MODAL DEPOSITAR ======================
    const [DepositarImporte, setDepositarImporte] = useState(''); // <<<<<< CAMBIO
    const [DepositarConcepto, setDepositarConcepto] = useState(''); // <<<<<< CAMBIO
    // Agrega un estado para controlar la visibilidad del modal
    const [ShowDepositar, setShowDepositar] = useState(false);
    const handleCloseDepositar = () => setShowDepositar(false);
    const handleShowDepositar = () => {
        setShowDepositar(true);
    };
    const FnCajaDepositar = async () => {
        const jsonData = {
            Idcajaapertura: id,
            Idempresa: Idempresa,
            DepositarImporte:DepositarImporte,
            DepositarConcepto:DepositarConcepto,
            Idusuario: Idusuario,
        };
        const token = localStorage.getItem('token-dincors');
        try{
            const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Depositar`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            })
            if(response.ok){
                const dataResponse = await response.json();
                if(dataResponse.res){
                    setDataMovimientoCaja(dataResponse.movimientocaja)
                    setToastBackground('#198754');
                    setToastMessage(dataResponse.msg);
                    setShowToast(true);
                }else{
                    throw new Error(dataResponse.msg);
                }
            }
        }catch(error){
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }finally{
            handleCloseDepositar();
            setDepositarImporte('');
            setDepositarConcepto('');
        };
    };
    // ====================== MODAL RETIRAR ======================
    const [RetirarImporte, setRetirarImporte] = useState(''); // <<<<<< CAMBIO
    const [Idsubtipomovimiento, setIdsubtipomovimiento] = useState(''); // <<<<<< CAMBIO
    const [RetirarConcepto, setRetirarConcepto] = useState(''); // <<<<<< CAMBIO
    // Agrega un estado para controlar la visibilidad del modal
    const [ShowRetirar, setShowRetirar] = useState(false);
    const handleCloseRetirar = () => setShowRetirar(false);
    const handleShowRetirar = () => {
        setShowRetirar(true);
    };
    const FnCajaRetirar = async () => {
        const jsonData = {
            Idcajaapertura: id,
            Idempresa: Idempresa,
            RetirarImporte:RetirarImporte,
            Idsubtipomovimiento:Idsubtipomovimiento,
            RetirarConcepto: RetirarConcepto,
            Idusuario: Idusuario,
        };
        const token = localStorage.getItem('token-dincors');
        try{
        const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Retirar`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(jsonData),
        })
        if(response.ok){
            const dataResponse = await response.json();
            if(dataResponse.res){
                setDataMovimientoCaja(dataResponse.movimientocaja)
                setToastBackground('#198754');
                setToastMessage(dataResponse.msg);
                setShowToast(true);
            }else{
                throw new Error(dataResponse.msg);
            }
        }
        }catch(error){
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }finally{
            handleCloseRetirar();
            setRetirarImporte('');
            setIdsubtipomovimiento('');
            setRetirarConcepto('');
        };
    };
    return (
        <>
        <HeadView/>
        <Sidebar />
        <div className="home-section">
            <RouterView RutaHead={RutaHead}></RouterView>
            <section className="container-fluid ">
                <div className='row '>
                    <div className='col-12 mt-3'>
                        <div className="card m-3">
                            <h5 className="card-header din-card-header">CUADRE DE CAJA # {id}</h5>
                            <div className="card-body mx-2">{/* CARD BODY */}
                                <div className="row">
                                    {/* PRIMERA COLUMNA */}
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 p-4 pt-2 pb-2" style={{background:'#F7F7F7'}}>
                                        {/* RESUMEN DE CAJA */}
                                        {/* Resumen 1 */}
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <h5 className="card-title text-success">Resumen de Caja</h5>
                                                <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                                            </div>
                                        </div>
                                        {/* Datos */}
                                        <div className="row m-2">
                                            <div className='col-8 mt-2 mb-2'>Saldo Inicial:</div>
                                            <div className='col-4 mt-2 mb-2 text-end'>{DataCajaApertura.moneda} {DataCajaApertura.saldo_apertura ? DataCajaApertura.saldo_apertura : "0.00"}</div>
                                            <div className='col-8 mt-2 mb-2'>Saldo de Hoy:</div>
                                            <div className='col-4 mt-2 mb-2 text-end'>{DataCajaApertura.moneda} {DataCajaApertura.saldo_cierre ? DataCajaApertura.saldo_cierre : "0.00"}</div>
                                            <div className='col-8 mt-2 mb-2'>Valor Calculado:</div>
                                            <div className='col-4 mt-2 mb-2 text-end'>{DataCajaApertura.moneda} {DataCajaApertura.monto_calculado ? DataCajaApertura.monto_calculado : "0.00"}</div>
                                            <div className='col-8 mt-2 mb-2'>Arqueo de Caja:</div>
                                            <div className='col-4 mt-2 mb-2 text-end'>{DataCajaApertura.moneda} {DataCajaApertura.monto_contado ? DataCajaApertura.monto_contado : "0.00"}</div>
                                            <div className='col-8 mt-2 mb-2'>Saldo Faltante:</div>
                                            <div className='col-4 mt-2 mb-2 text-end'>
                                                {DataCajaApertura.diferencia && parseFloat(DataCajaApertura.diferencia) < 0 ? 
                                                    <span className='text-danger'>{DataCajaApertura.moneda} {DataCajaApertura.diferencia}</span>
                                                : 
                                                    <span>{DataCajaApertura.moneda} 0.00</span>
                                                }    
                                            </div>
                                            <div className='col-8 mt-2 mb-2'>Saldo Sobrante:</div>
                                            <div className='col-4 mt-2 mb-2 text-end'>
                                                {DataCajaApertura.diferencia && parseFloat(DataCajaApertura.diferencia) > 0 ? 
                                                    <span className='text-success'>{DataCajaApertura.moneda} {DataCajaApertura.diferencia }</span>
                                                : 
                                                    <span>{DataCajaApertura.moneda} 0.00</span>
                                                }    
                                            </div>
                                        </div>
                                        {/* Resumen 2 */}
                                        <div className="row mt-4">
                                            <div className="col-12">
                                                <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                                            </div>
                                        </div>
                                        {/* Datos */}
                                        <div className="row m-2">
                                            <div className='col-8 mt-2 mb-2'>Ventas Efectivo:</div>
                                            <div className='col-4 mt-2 mb-2 text-end'>{DataCajaApertura.moneda} {DataCajaApertura.monto_efectivo ? DataCajaApertura.monto_efectivo : "0.00"}</div>
                                            <div className='col-8 mt-2 mb-2 d-flex align-items-center'>Recuento Efectivo:</div>
                                            <div className='col-4 mt-2 mb-2 text-end'>
                                                <InputTextImporte value={RecuentoEfectivo} campo="Importe" name="RecuentoEfectivo" obligatorio="*" colsm="12" colmd="12" onChange={setRecuentoEfectivo}/>
                                            </div>
                                            <div className='col-8 mt-2 mb-2'>Ventas por Tarjeta:</div>
                                            <div className='col-4 mt-2 mb-2 text-end'>{DataCajaApertura.moneda} {DataCajaApertura.monto_sin_efectivo ? DataCajaApertura.monto_sin_efectivo : "0.00"}</div>
                                            <div className='col-8 mt-2 mb-2 d-flex align-items-center'>Recuento por Tarjeta:</div>
                                            <div className='col-4 mt-2 mb-2 text-end'>
                                                <InputTextImporte value={RecuentoSinEfectivo} campo="Importe" name="RecuentoSinEfectivo" obligatorio="*" colsm="12" colmd="12" onChange={setRecuentoSinEfectivo}/>
                                            </div>
                                            <div className='col-8 mt-2 mb-2'>Ventas Externas:</div>
                                            <div className='col-4 mt-2 mb-2 text-end'>{DataCajaApertura.moneda} {DataCajaApertura.ventas_internas ? DataCajaApertura.ventas_internas : "0.00"}</div>
                                            <div className='col-8 mt-2 mb-2 d-flex align-items-center'>Recuento Ventas Externas:</div>
                                            <div className='col-4 mt-2 mb-2 text-end'>
                                                <InputTextImporte value={RecuentoInternas} campo="Importe" name="RecuentoInternas" obligatorio="*" colsm="12" colmd="12" onChange={setRecuentoInternas}/>
                                            </div>
                                        </div>
                                        <div className='row m-2 mt-4'>
                                            <button className="btn btn-success din-btn-style" onClick={() => handleShowDetalle(id)}>
                                                <i className="fa-solid fa-plus"></i>&nbsp;&nbsp;Detalle
                                            </button>
                                        </div>
                                    </div>
                                    {/* SEGUNDA COLUMNA */}
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-8 p-4 pt-2 pb-2" id='TabsCaja'>
                                        <Tabs id="controlled-tab-example" defaultActiveKey="Movimientos" className="mb-0" >
                                            <Tab eventKey="Movimientos" title="Movimientos" className='border-bottom border-end border-start'>
                                                <div className="row" style={{padding: "0px 0px 0px 10.5px", maxHeight: "500px",  overflowY: "scroll", overflowX:"hidden"}}>
                                                    <div className='col-12'>
                                                        <div className='row p-1 d-flex justify-content-end mt-2 mb-2'>
                                                            <div className='custom-col'>
                                                                <button className="btn btn-secondary din-btn-style w-100" onClick={() => handleShowDepositar()} disabled={DataCajaApertura.idestado===2 ? true : false}>
                                                                    <i className="fa-solid fa-plus"></i>&nbsp;&nbsp;Depositar
                                                                </button>
                                                            </div>
                                                            <div className='custom-col'>
                                                                <button className="btn btn-secondary din-btn-style w-100" onClick={() => handleShowRetirar()} disabled={DataCajaApertura.idestado===2 ? true : false}>
                                                                    <i className="fa-solid fa-minus"></i>&nbsp;&nbsp;Retirar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {DataMovimientoCaja.map((data, index) => (
                                                        <div className={`col-12 ${index === 0 ? 'border-top' : ''} ${index === DataMovimientoCaja.length - 1 ? '' : 'border-bottom'} p-2`} key={index}>
                                                            <div className='row'>
                                                                <div className='col-8 col-sm-8 col-md-9'>
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            {data.concepto}
                                                                        </div>
                                                                        <div className='col-12 texto-lg'>
                                                                            <span className='texto-lg' style={{ fontWeight: '600' }}>{data.saldo}</span> {data.moneda}&nbsp;&nbsp;&nbsp;
                                                                            <span className={`texto-xs badge ${[1, 4, 5, 8].includes(data.idtipomovimiento) ? 'bg-danger' : 'bg-success'}`}>{data.tipomovimiento}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-4 col-sm-4 col-md-3'>
                                                                    <div className='row '>
                                                                        <div className='col-12 text-end' style={{ fontWeight: '600' }}>
                                                                            Debe: {data.debe ? parseFloat(data.debe).toFixed(2) : "0.00"}
                                                                        </div>
                                                                        <div className='col-12 text-end texto-lg' style={{ fontWeight: '600' }}>
                                                                            Haber: {data.haber ? parseFloat(data.haber).toFixed(2) : "0.00"}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Tab>
                                            <Tab eventKey="Kardex" title="Kardex">
                                                <div className="row" style={{padding: "0px 0px 0px 10.5px", maxHeight: "500px",  overflowY: "scroll", overflowX:"hidden"}}>
                                                    {DataKardexCaja.map((data, index) => (
                                                        <div className={`col-12 border-bottom border-start p-2`} key={index}>
                                                            <div className='row'>
                                                                <div className='col-6 col-sm-6 col-md-8'>
                                                                    <div className='row h-100'>
                                                                        <div className='col-12 col-sm-5 d-flex align-items-center'>
                                                                            <span>{data.fechreg}</span>
                                                                        </div>
                                                                        <div className='col-12 col-sm-7 d-flex align-items-center'>
                                                                            <span style={{color:'#2FAC68'}}>{data.itemnom}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-6 col-sm-6 col-md-4'>
                                                                    <div className='row'>
                                                                    <div className='col-6'>
                                                                            <div className='row '>
                                                                                <div className='col-12 text-end'>
                                                                                    Inicial: {data.itemstockini ? parseFloat(data.itemstockini).toFixed(2) : "0.00"}
                                                                                </div>
                                                                                <div className='col-12 text-end'>
                                                                                    Stock: {data.itemstock ? parseFloat(data.itemstock).toFixed(2) : "0.00"}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <div className='row '>
                                                                                <div className='col-12 text-end'>
                                                                                    {parseFloat(data.itemcantentrada) > 0 ? 
                                                                                        <span className='text-success'>Entrada: {parseFloat(data.itemcantentrada).toFixed(2)}</span>
                                                                                    : 
                                                                                        "Entrada: 0.00"
                                                                                    }
                                                                                </div>
                                                                                <div className='col-12 text-end'>
                                                                                    {parseFloat(data.itemcantsalida) > 0 ? 
                                                                                        <span className='text-danger'>Salida: {parseFloat(data.itemcantsalida).toFixed(2)}</span>
                                                                                    : 
                                                                                        "Salida: 0.00"
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Tab>
                                        </Tabs> 
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                {/* Botones */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className='row p-1 d-flex justify-content-end'>
                                            {DataCajaApertura.idestado===2 ?
                                                <>
                                                    <div className="col-12 col-sm-6 col-md-6 mb-2">
                                                        <button className="btn btn-outline-success din-btn-style w-100" disabled>Cuadre de Caja</button>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-6 mb-2">
                                                        <button className="btn btn-outline-success din-btn-style w-100" disabled>Cierre de Caja</button>
                                                    </div>
                                                </>
                                            :
                                                <>
                                                    <Link className="col-12 col-sm-6 col-md-6 mb-2" onClick={() => handleShowRecuentoInventario()}>
                                                        <button className="btn btn-outline-success din-btn-style w-100">Cuadre de Caja</button>
                                                    </Link>
                                                    <Link className="col-12 col-sm-6 col-md-6 mb-2" onClick={() => handleShowCierreCaja()} >
                                                        <button className="btn btn-outline-success din-btn-style w-100">Cierre de Caja</button>
                                                    </Link>
                                                </>
                                            }
                                            {/* <Link className="col-12 col-sm-4 col-md-4 mb-2" onClick={FnControlInventario}>
                                                <button className="btn btn-outline-success din-btn-style w-100">Realizar Control Inventario</button>
                                            </Link> */}

                                            
                                        </div>   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={4000} autohide className='text-white'
                style={{position: 'fixed',top: '20px',right: '20px',zIndex: 1000,background: `${toastBackground}`,}}>
                <Toast.Header>
                    <strong className="me-auto">Notificación</strong>
                    <small></small>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
            <FooterView/>
        </div>
        {/* Modal Depositar*/}
        <Modal show={ShowDepositar} onHide={handleCloseDepositar}>
            <Modal.Header  className='bg-success text-white' closeButton>
              <Modal.Title>Depositar Dinero</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <InputText value={DepositarImporte} campo="Importe" name="DepositarImporte" obligatorio="*" colsm="12" colmd="12" onChange={setDepositarImporte}/>  {/* <<<<<< CAMBIO */}
              <InputText value={DepositarConcepto} campo="Concepto" name="DepositarConcepto" obligatorio="*" colsm="12" colmd="12" onChange={setDepositarConcepto}/>  {/* <<<<<< CAMBIO */}
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-secondary din-btn-style" onClick={handleCloseDepositar}>
                <i className="fa-solid fa-xmark"></i>&nbsp;&nbsp;Cancelar
              </button>
              <button className="btn btn-success din-btn-style" onClick={FnCajaDepositar}>
                <i className="fa-solid fa-check"></i>&nbsp;&nbsp;Aceptar
              </button>
            </Modal.Footer>
        </Modal>
        {/* Modal Retirar*/}
        <Modal show={ShowRetirar} onHide={handleCloseRetirar}>
            <Modal.Header  className='bg-success text-white' closeButton>
                <Modal.Title>Retiro de Dinero</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputText value={RetirarImporte} campo="Importe" name="RetirarImporte" obligatorio="*" colsm="12" colmd="12" onChange={setRetirarImporte}/>
                <InputComboBoxTextId campo="Movimiento" name="Idsubtipomovimiento" obligatorio="*" colsm="12" colmd="12" url={url} 
                onChange={(selectedValue) => {setIdsubtipomovimiento(selectedValue);}} />
                <InputText value={RetirarConcepto} campo="Concepto" name="RetirarConcepto" obligatorio="*" colsm="12" colmd="12" onChange={setRetirarConcepto}/>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary din-btn-style" onClick={handleCloseRetirar}>
                    <i className="fa-solid fa-xmark"></i>&nbsp;&nbsp;Cancelar
                </button>
                <button className="btn btn-success din-btn-style" onClick={FnCajaRetirar}>
                    <i className="fa-solid fa-check"></i>&nbsp;&nbsp;Aceptar
                </button>
            </Modal.Footer>
        </Modal>
        {/* Modal RecuentoInventario*/}
        <Modal show={ShowRecuentoInventario} onHide={handleCloseRecuentoInventario}>
            <Modal.Header  className='bg-success text-white' closeButton>
              <Modal.Title>Recuento de Inventario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              ¿Esta seguro de realizar el recuento y actualizacion de inventario?
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-secondary din-btn-style" onClick={handleCloseRecuentoInventario}>
                <i className="fa-solid fa-xmark"></i>&nbsp;&nbsp;Cancelar
              </button>
              <button className="btn btn-success din-btn-style" onClick={FnCajaRecuentoInventario}>
                <i className="fa-solid fa-check"></i>&nbsp;&nbsp;Aceptar
              </button>
            </Modal.Footer>
        </Modal>
        {/* Modal CierreCaja*/}
        <Modal show={ShowCierreCaja} onHide={handleCloseCierreCaja}>
            <Modal.Header  className='bg-success text-white' closeButton>
              <Modal.Title>Cierre de Caja</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              ¿Esta seguro de realizar el cierre de caja?
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-secondary din-btn-style" onClick={handleCloseCierreCaja}>
                <i className="fa-solid fa-xmark"></i>&nbsp;&nbsp;Cancelar
              </button>
              <button className="btn btn-success din-btn-style" onClick={FnCierreCaja}>
                <i className="fa-solid fa-check"></i>&nbsp;&nbsp;Aceptar
              </button>
            </Modal.Footer>
        </Modal>
        {/* Modal Detalle*/}
        <Modal show={showDetalle} onHide={CloseDetalle} size="lg">
            <Modal.Header className='bg-success text-white' closeButton>
              <Modal.Title style={{ textTransform: 'uppercase' }}>Cierre de {DataDetalle.cajanom} | ID {DataDetalle.idcajaapertura}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* CARD BODY */}
              <div className="card-body mx-2 p-3">
                    <div className='row m-1 mb-0'>
                        <div className='p-1 pb-0'>
                          Saldo Final de Caja:&nbsp;
                          <span style={{fontWeight:'600'}}>{DataDetalle.moneda} {DataDetalle.saldo_total_caja ? parseFloat(DataDetalle.saldo_total_caja).toFixed(2) : "0.00"}</span>
                        </div>
                    </div>
                    <hr style={{borderTop: "2px solid #198754", opacity:"0.6"}}/>
                    <div className='row m-2'>
                        <div className='col-12 col-sm-6 col-md-6 border'>
                            <div className='row'>
                                    <div className='col-12 text-center border-bottom p-2' style={{fontWeight:'600'}}>INGRESOS</div>
                                    <div className='col-7 p-1 px-4 pt-3'>Saldo inicial:&nbsp;</div>
                                    <div className='col-5 p-1 px-4 pt-3'>{DataDetalle.moneda} {DataDetalle.saldo_apertura ? parseFloat(DataDetalle.saldo_apertura).toFixed(2) : "0.00"}</div>
                                    <div className='col-7 p-1 px-4'>Deposito:&nbsp;</div>
                                    <div className='col-5 p-1 px-4'>{DataDetalle.moneda} {DataDetalle.monto_deposito ? parseFloat(DataDetalle.monto_deposito).toFixed(2) : "0.00"}</div>
                                    <div className='col-7 p-1 px-4'>Ventas:&nbsp;</div>
                                    <div className='col-5 p-1 px-4'>{DataDetalle.moneda} {DataDetalle.monto_pagado ? parseFloat(DataDetalle.monto_pagado).toFixed(2) : "0.00"}</div>
                                    <div className='col-7 p-1 px-4'><li>Efectivo:&nbsp;</li></div>
                                    <div className='col-5 p-1 px-4'>{DataDetalle.moneda} {DataDetalle.pago_efectivo ? parseFloat(DataDetalle.pago_efectivo).toFixed(2) : "0.00"}</div>
                                    <div className='col-7 p-1 px-4'><li>Tarjeta:&nbsp;</li></div>
                                    <div className='col-5 p-1 px-4'>{DataDetalle.moneda} {DataDetalle.pago_tarjeta ? parseFloat(DataDetalle.pago_tarjeta).toFixed(2) : "0.00"}</div>
                                    <div className='col-7 p-1 px-4 pb-3'><li>Ventas Externas:&nbsp;</li></div>
                                    <div className='col-5 p-1 px-4 pb-3'>{DataDetalle.moneda} {DataDetalle.ventas_internas ? parseFloat(DataDetalle.ventas_internas).toFixed(2) : "0.00"}</div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-md-6 border'>
                            <div className='row'>
                                <div className='col-12 text-center border-bottom p-2' style={{fontWeight:'600'}}>EGRESOS</div>
                                <div className='col-7 p-1 px-4 pt-3 pb-3'>Gastos:&nbsp;</div>
                                <div className='col-5 p-1 px-4 pt-3 pb-3'>{DataDetalle.moneda} {DataDetalle.monto_retiro ? parseFloat(DataDetalle.monto_retiro).toFixed(2) : "0.00"}</div>
                            </div>
                        </div>
                    </div>
                    <hr style={{borderTop: "2px solid #198754", opacity:"0.6"}}/>
                    <div className='row m-2'>
                            <div className='col-12 col-sm-6 col-md-6 border'>
                                <div className='row'>
                                    <div className='col-7 p-1 px-4 pt-3'>Saldo de Hoy:&nbsp;</div>
                                    <div className='col-5 p-1 px-4 pt-3'>{DataDetalle.moneda} {DataDetalle.saldo_cierre ? parseFloat(DataDetalle.saldo_cierre).toFixed(2) : "0.00"}</div>
                                    <div className='col-7 p-1 px-4'>Valor Calculado:&nbsp;</div>
                                    <div className='col-5 p-1 px-4'>{DataDetalle.moneda} {DataDetalle.venta_total ? parseFloat(DataDetalle.venta_total).toFixed(2) : "0.00"}</div>
                                    <div className='col-7 p-1 px-4'>Valor Contado:&nbsp;</div>
                                    <div className='col-5 p-1 px-4'>{DataDetalle.moneda} {DataDetalle.monto_contado ? parseFloat(DataDetalle.monto_contado).toFixed(2) : "0.00"}</div>
                                    <div className='col-7 p-1 px-4'>Saldo Faltante:&nbsp;</div>
                                    <div className='col-5 p-1 px-4'>
                                        {DataDetalle.diferencia && parseFloat(DataDetalle.diferencia) < 0 ? 
                                            <span className='text-danger'>{DataDetalle.moneda} {DataDetalle.diferencia}</span>
                                        : 
                                            <span>{DataDetalle.moneda} 0.00</span>
                                        }   
                                    </div>
                                    <div className='col-7 p-1 px-4 pb-3'>Saldo Sobrante:&nbsp;</div>
                                    <div className='col-5 p-1 px-4 pb-3'>
                                        {DataDetalle.diferencia && parseFloat(DataDetalle.diferencia) > 0 ? 
                                            <span className='text-success'>{DataDetalle.moneda} {DataDetalle.diferencia }</span>
                                        : 
                                            <span>{DataDetalle.moneda} 0.00</span>
                                        } 
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-6 border'>
                                {/* <div className='row'>
                                    <div className='col-7 p-1 px-4 pt-3 pb-3'>Saldo Pendiente:&nbsp;</div>
                                    <div className='col-5 p-1 px-4 pt-3 pb-3'>
                                    {DataDetalle.pendiente ? parseFloat(DataDetalle.pendiente).toFixed(2) : "0.00"}
                                    </div>
                                    
                                </div> */}
                            </div>
                    </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-secondary din-btn-style" onClick={CloseDetalle}>
                <i className="fa-solid fa-xmark"></i>&nbsp;&nbsp;Cerrar
              </button>
            </Modal.Footer>
        </Modal>
        </>
    );
}

export default ItemUpd;


