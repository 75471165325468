// import Button from 'react-bootstrap/Button';
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { InputComboBoxComprobante } from "../../componentes/agregar/comprobante/InputComboBoxComprobante";
import { InputComboBoxIGV } from "../../componentes/agregar/comprobante/InputComboBoxIGV";
import { InputComboBoxTipoDocumento } from "../../componentes/agregar/comprobante/InputComboBoxTipoDocumento";
import { InputText } from "../../componentes/editar/InputText";
import { InputComboBoxMedio } from "../../componentes/agregar/comprobante/InputComboBoxMedio";
import { InputDateTime } from "../../componentes/editar/InputDateTime";
// Item
import { InputComboBoxBusquedaItem } from "../../componentes/editar/alquiler/InputComboBoxBusquedaItem";
import { InputTextPrecioUnitario } from "../../componentes/editar/alquiler/InputTextPrecioUnitario";
import { InputTextCantidad } from "../../componentes/editar/alquiler/InputTextCantidad";
import { InputComboBoxTributo } from "../../componentes/editar/alquiler/InputComboBoxTributo";
// import { InputComboBoxFormaPago } from "../../componentes/editar/alquiler/InputComboBoxFormaPago";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import Toast from 'react-bootstrap/Toast';
import  RouterView  from '../../componentes/layout/RouterView';
import Sidebar from "../../pages/Sidebar";
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import '../../Assets/css/din_style_2.css'
import '../../Assets/css/StyleSlidebar.css'
import { useSelector } from 'react-redux';
import API_BASE_URL  from "../../config";
const nombre_controller = 'Comprobantes';        // <<<<<< CAMBIO
const url = `${API_BASE_URL}`;       // <<<<<< CAMBIO
const RutaHead = [
    { name: 'Comprobante', ruta: `/${nombre_controller}List` },          // <<<<<< CAMBIO
    { name: 'Edicion de Comprobante', ruta: `/${nombre_controller}Upd` },  // <<<<<< CAMBIO
];
function ComprobanteUpd() {
    const { id } = useParams();
    //Redux
    const user = useSelector((state) => state.user);
    const [Idempresa, setIdempresa] = useState(user.companyId);
    const [Idusuario, setIdusuario] = useState(user.userId);
    const obtenerFechaHoraActual = () => {
        const ahora = new Date(); // Obtiene la fecha y hora actual
        const year = ahora.getFullYear();
        const month = String(ahora.getMonth() + 1).padStart(2, '0');
        const day = String(ahora.getDate()).padStart(2, '0');
        const hours = String(ahora.getHours()).padStart(2, '0');
        const minutes = String(ahora.getMinutes()).padStart(2, '0');
        const fechaHoraFormateada = `${year}-${month}-${day} ${hours}:${minutes}`;
        return fechaHoraFormateada;
    };
    // Función para actualizar el usuario en el estado local cuando cambie en Redux
    useEffect(() => {
        setIdempresa(user.companyId);
        setIdusuario(user.userId);
    }, [user]);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBackground , setToastBackground ] = useState('#198754');
    //Comprobante
    const [Tipo, setTipo] = useState('');                 // <<<<<< CAMBIO
    const [Nombre, setNombre] = useState('');                 // <<<<<< CAMBIO
    const [Clitipo, setClitipo] = useState('');               // <<<<<< CAMBIO
    const [Cliruc, setCliruc] = useState('');                   // <<<<<< CAMBIO
    const [Clinombre, setClinombre] = useState('');   // <<<<<< CAMBIO
    const [Clidireccion, setClidireccion] = useState('');   // <<<<<< CAMBIO
    const [Clicorreo, setClicorreo] = useState('');   // <<<<<< CAMBIO
    const [Mediopago, setMediopago] = useState('');   // <<<<<< CAMBIO
    const [Formapago] = useState('Contado');   // <<<<<< CAMBIO
    const [Notas, setNotas] = useState('');   // <<<<<< CAMBIO
    const [Igv, setIgv] = useState('');                 // <<<<<< CAMBIO
    const [FechaEmision, setFechaEmision] = useState(obtenerFechaHoraActual());
    const [Cliid, setCliid] = useState('');             // <<<<<< CAMBIO
    const [Estado, setEstado] = useState(false);             // <<<<<< CAMBIO
    //Items
    const [Iditem, setIditem] = useState('');
    const [Itemcodigo, setItemcodigo] = useState('');
    const [Itemdesc, setItemdesc] = useState('');
    const [PrecioUnitario, setPrecioUnitario] = useState('');
    const [CantidadItem, setCantidadItem] = useState(1);
    const [TributoCodigo, setTributoCodigo] = useState('1000');
    const [Idcajaapertura, setIdcajaapertura] = useState('');                 // <<<<<< CAMBIO
    // const [Idformapago, setIdformapago] = useState('1');
    // const [Txtformapago, setTxtformapago] = useState('EFECTIVO');
    const [TotalVenta, setTotalVenta] = useState(0.00);
    const [DataVentasItem, setDataVentasItem]=useState([]);
    // Venta total
    useEffect(() => {
        if (DataVentasItem && DataVentasItem.length > 0) {
            const initialTotal = DataVentasItem.reduce((acc, data) => {
                if (data.Itemcodigo === "SERV") {
                    // Sumar solo el PrecioUnitario (convertir a número antes de sumar)
                    return acc + parseFloat(data.PrecioUnitario);
                } else {
                    // Sumar PrecioUnitario multiplicado por CantidadItem (convertir a número antes de multiplicar)
                    return acc + (parseFloat(data.PrecioUnitario) * parseFloat(data.CantidadItem));
                }
            }, 0);
    
            setTotalVenta(initialTotal);
        } else {
            setTotalVenta(0);
        }
    }, [DataVentasItem]);
    // ====================== MODAL AGREGAR VENTA ======================
    const [ShowAgregarVenta, setShowAgregarVenta] = useState(false);
    const CloseVentaModal = () => setShowAgregarVenta(false);
    const handleShowAgregarVenta = () => {
        setShowAgregarVenta(true);
    };
    // Guardar la venta
    const FnAgregarVenta = async () => {
        const jsonData = {
            Iditem: Iditem,
            Itemcodigo: Itemcodigo,
            Itemdesc: Itemdesc,
            PrecioUnitario: PrecioUnitario,
            CantidadItem: CantidadItem,
            TributoCodigo: TributoCodigo,
            // Idformapago: Idformapago,
            // Txtformapago: Txtformapago,
            Idempresa: Idempresa,
            Idusuario: Idusuario,
        };
        setDataVentasItem(prevData => [...prevData, jsonData]);
        //notificacion
        setToastBackground('#198754');
        setToastMessage('Item agregado correctamente');
        setShowToast(true);
        //limpiando datos
        setShowAgregarVenta(false);
        setIditem('');
        setItemcodigo('');
        setItemdesc('');
        setPrecioUnitario('');
        setCantidadItem(1);
        setTributoCodigo('1000');
        // setIdformapago('1');
        // setTxtformapago('EFECTIVO');
    };
    // ====================== MODAL ELIMINAR VENTA ======================
    // Agrega un estado para controlar la visibilidad del modal
    const [ShowRetirarVenta, setShowRetirarVenta] = useState(false);
    const CloseRetirarVentaModal = () => setShowRetirarVenta(false);
    // const handleShow = () => setShow(true);
    const [IdItemVenta, setIdItemVenta] = useState(null);
    const handleShowRetirarVentaModal = (itemId) => {
        setIdItemVenta(itemId);
        setShowRetirarVenta(true);
    };
    // Retirar la venta
    const FnRetirarVenta = async () => {
        const newDataVentasItem = [...DataVentasItem];
        newDataVentasItem.splice(IdItemVenta, 1);
        setDataVentasItem(newDataVentasItem);
        setIdItemVenta(null);
        setShowRetirarVenta(false);
    };
    // Actualizar venta
    const FnActualizar = async () => {
        if(Cliruc.trim() !== '' && Clinombre.trim() !== '' && Clidireccion.trim() !== '' && 
            Tipo !== '' && Clitipo !== '' && Mediopago !== '' && Formapago !== '' && FechaEmision.trim() !== ''){ // <<<<<< CAMBIO
            let cant = Cliruc.toString().length;        // <<<<<< CAMBIO
            const jsonData = {
                Idfactura: id,
                Tipo: Tipo,         // <<<<<< CAMBIO
                Nombre: Nombre,     // <<<<<< CAMBIO
                Cliid: Cliid,
                Clitipo: Clitipo,     // <<<<<< CAMBIO
                Cliruc: Cliruc,     // <<<<<< CAMBIO
                Clinombre: Clinombre,         // <<<<<< CAMBIO
                Clidireccion: Clidireccion, // <<<<<< CAMBIO
                Clicorreo: Clicorreo,     // <<<<<< CAMBIO
                Mediopago: Mediopago,       // <<<<<< CAMBIO
                Formapago: Formapago,         // <<<<<< CAMBIO
                Notas: Notas,         // <<<<<< CAMBIO
                Igv: Igv,
                FechaEmision: FechaEmision,
                DataVentasItem: DataVentasItem,
                Idempresa: Idempresa,// <<<<<< CAMBIO
                Idusuario: Idusuario,
            };
            const token = localStorage.getItem('token-dincors');
            //console.log("fac: ", jsonData)
            try {
                if(Clitipo==='6' && cant !== 11){throw new Error(`Operación detenida. El RUC presenta ${cant} carácteres, debe tener 11 carácteres.`);}
                if(Clitipo==='1' && cant !== 8){throw new Error(`Operación detenida. El DNI presenta ${cant} carácteres, debe tener 8 carácteres.`);}
                const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Update`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(jsonData),
                });
                if(response.ok){                
                    const data = await response.json();
                    console.log("actualizar: ",data)
                    if(data.res){
                        setToastMessage(data.msg);
                        setShowToast(true);
                        setToastBackground('#198754');
                    }else{
                        throw new Error(data.msg);
                    }
                }else  {
                    throw new Error(`${response.status} ${response.statusText}`);
                }
            }catch (error){
                setToastBackground('#CC6868');
                setToastMessage(error.message);
                setShowToast(true);
            }
        }else{
            setToastMessage(`Operación detenida. Llene todos los campos obligatorios.`);
            setShowToast(true);
            setToastBackground('#CC6868');
        }
    };
    // Guardar venta
    //const navigate = useNavigate();
    // const FnPresentar = async(value) => {
    //     console.log("22")
    //     if(value){
    //         localStorage.setItem('toastMessage2', 'Verifique los datos del comprobantes.');
    //         localStorage.setItem('toastBackground2', '#198754');
    //         navigate(`/ComprobantesDetail/${value}`);
    //         console.log(`/ComprobantesDetail/${value}`)
    //     }
    // };
    useEffect(() => {
        const DataComprobante = async ()=>{
            const token = localStorage.getItem('token-dincors');
            const jsonData = {
                Idfactura: id,
                Idempresa: user.companyId, // <<<<<< CAMBIO
                Codusuact: user.userId,
            };
            try {
                const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Upd`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(jsonData),
                });
                if(response.ok){
                    const data = await response.json();
                    console.log("ubicar: ", data)
                    if(data.res){
                        setTipo(data.datos.tipo);
                        setNombre(data.datos.nombre);
                        setClitipo(data.datos.clitipo);
                        setCliruc(data.datos.cliruc);
                        setClinombre(data.datos.clinombre);
                        setClidireccion(data.datos.clidireccion);
                        setClicorreo(data.datos.clicorreo);
                        setMediopago(data.datos.mediopago);
                        //setFormapago(data.datos.formapago);
                        setNotas(data.datos.notas);
                        setIgv(data.datos.igv);
                        setFechaEmision(data.datos.fechaemision);
                        setCliid(data.datos.cliid);
                        if(data.datos.idalquiler===null){
                            if(data.datos.estado === "PRESENTADO"){
                                setEstado(true);
                            }else{
                                setEstado(false);
                            }
                        }else{
                            setEstado(true);
                        }
                        setDataVentasItem(data.ventas);
                        console.log("items: ",data.ventas)
                        setIdcajaapertura(data.idcajaapertura);
                    }else{
                        throw new Error(data.msg);
                    }
                }else{
                    throw new Error(`${response.status} ${response.statusText}`);
                }
            }catch (error) {
                setToastBackground('#CC6868');
                setToastMessage(error.message);
                setShowToast(true);
            }
        }
        DataComprobante();
        // eslint-disable-next-line
    }, [id]);
    useEffect(() => {
        const storedMessage = localStorage.getItem('toastMessage1');
        const storedBackground = localStorage.getItem('toastBackground1');
        if (storedMessage && storedBackground) {
            setToastMessage(storedMessage);
            setToastBackground(storedBackground);
            setShowToast(true);
            localStorage.removeItem('toastMessage1');
            localStorage.removeItem('toastBackground1');
        }
    }, []);
    return (
        <>
        <HeadView/>
        <Sidebar />
        <div className="home-section">
            <RouterView RutaHead={RutaHead}></RouterView>
            <div className="card m-3">
                <h5 className="card-header din-card-header">DATOS GENERALES</h5>
                <div className="card-body mx-2">
                    {/* Titulo */}
                    <div className="row mt-3">
                        <div className="col-12">
                            <h5 className="card-title text-success">Datos Generales</h5>
                            <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                        </div>
                    </div>
                    {/* Datos */}
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <InputDateTime estado={Estado} value={FechaEmision} campo="Fecha de Emision" name="FechaEmision" obligatorio="*" colsm="6" colmd="4" onChange={setFechaEmision}/>
                                <InputComboBoxComprobante estado={Estado} value={Tipo} campo="Comprobante" name="Tipo" obligatorio="*" colsm="6" colmd="4" 
                                    onChange={(IdValue, TextValue) => {setTipo(IdValue);setNombre(TextValue);}}/>
                                <InputComboBoxTipoDocumento estado={Estado} value={Clitipo} campo="Tipo de Documento" name="Clitipo" obligatorio="*" colsm="6" colmd="4" onChange={setClitipo}/>         {/* <<<<<< CAMBIO */}
                                <InputText estado={Estado} value={Cliruc} campo="Numero de documento" name="Cliruc" obligatorio="*" colsm="6" colmd="4" onChange={setCliruc}/>
                                <InputText estado={Estado} value={Clinombre} campo="Nombre/Razon Social" name="Clinombre" obligatorio="*" colsm="6" colmd="8" onChange={setClinombre}/>  {/* <<<<<< CAMBIO */}
                                <InputText estado={Estado} value={Clicorreo} campo="Correo Electronico" name="Clicorreo" obligatorio="" colsm="6" colmd="4" onChange={setClicorreo}/>           {/* <<<<<< CAMBIO */}
                                <InputText estado={Estado} value={Clidireccion} campo="Direccion" name="Clidireccion" obligatorio="*" colsm="12" colmd="8" onChange={setClidireccion}/>               {/* <<<<<< CAMBIO */}
                                <InputComboBoxMedio estado={Estado} value={Mediopago} campo="Medio de pago" name="Mediopago" obligatorio="*" colsm="6" colmd="4"  
                                    onChange={(IdValue, TextValue) => {setMediopago(IdValue);}}/> {/* <<<<<< CAMBIO */}
                                {/* <div className={`col-12 col-sm-6 col-md-4 mb-3`}>
                                    <label htmlFor={`cbxFormapago`} className="form-label">Forma de pago<span className="text-danger">*</span></label>
                                    <div className="input-group">
                                        <select className="form-select" id={`cbxFormapago`} 
                                        onChange={(e) => setFormapago(e.target.value)} value={Formapago || ""}>
                                            <option value="">- Forma de pago -</option>
                                            <option value="01">Al Contado</option>
                                            <option value="02">Al Credito</option>
                                        </select>
                                        <button className="btn btn-outline-secondary" type="button" disabled={Formapago==="02" ? false : true}>Cuotas</button>
                                    </div>
                                </div> */}
                                <InputText estado={Estado} value={Notas} campo="Notas" name="Notas" obligatorio="" colsm="6" colmd="4" onChange={setNotas}/>           {/* <<<<<< CAMBIO */}
                                <InputComboBoxIGV estado={Estado} value={Igv} campo="I.G.V." name="Igv" obligatorio="*" colsm="6" colmd="4" 
                                onChange={(IdValue) => {setIgv(IdValue);}}/>
                            </div>
                        </div>
                    </div>
                    {/* Titulo */}
                    <div className="row mt-3">
                        <div className="col-12">
                            <h5 className="card-title text-success">Productos</h5>
                            <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-sm-6 col-md-6 mb-3'>
                            <button onClick={() => handleShowAgregarVenta()} className="btn btn-outline-success din-btn-style w-100 h-100" disabled={Estado}>
                                <i className="fa-solid fa-plus"></i>&nbsp;Agregar
                            </button>
                        </div>
                    </div>
                    <div className="row" style={{padding: "0px 10.5px"}}>
                        {DataVentasItem.map((data, index) => (
                            <div className={`col-12 border-bottom border-start border-end ${index === 0 ? 'border-top' : ''} p-2`} key={index}>
                                <div className='row'>
                                    <div className='col-10 col-sm-10 col-md-10'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                {data.Itemcodigo} {data.Itemdesc}
                                                &nbsp;|&nbsp;<span className={`text-black texto-mediano`}>VENTA</span>
                                            </div>
                                            <div className='col-12 texto-lg'>
                                                <span className='texto-lg' style={{ fontWeight: '600' }}>{parseFloat(data.CantidadItem).toFixed(2)}</span> UNIDAD&nbsp;&nbsp;
                                                <span className={`texto-xs badge bg-success`}>PAGADO</span>&nbsp;&nbsp;
                                                <span className={`text-black texto-xs badge bg-warning`}>{Idcajaapertura}</span>
                                                &nbsp;&nbsp;<span className={`text-black texto-mediano`}>
                                                    {{'1000': 'OPERACION GRAVADO',
                                                    '9996': 'OPERACION GRATUITO',
                                                    '9997': 'OPERACION EXONERADA',
                                                    '9998': 'OPERACION INAFECTA',
                                                    }[data.TributoCodigo]}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-2 col-sm-2 col-md-2'>
                                        <div className='row '>
                                            <div className='col-12 text-end'>
                                                { Estado ?
                                                    <div className='text-secondary'>
                                                        <i className="fas fa-trash fa-lg"></i>
                                                    </div>
                                                    :
                                                    <Link className='text-danger' onClick={() => handleShowRetirarVentaModal(index)}>
                                                        <i className="fas fa-trash fa-lg"></i>
                                                    </Link>
                                                }
                                                
                                            </div>
                                            <div className='col-12 text-end texto-lg' style={{ fontWeight: '600' }}>
                                                { data.Itemcodigo === 'SERV' ?
                                                    parseFloat(data.PrecioUnitario).toFixed(2)
                                                :
                                                    parseFloat(data.CantidadItem*data.PrecioUnitario).toFixed(2)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='row mt-2' style={{ marginRight:'1px' }}>
                        <div className='col-10 text-end' style={{ fontWeight: '600' }}>Total</div>
                        <div className='col-2 text-end' style={{ fontWeight: '600'}}>{TotalVenta.toFixed(2)}</div>
                    </div>
                </div>
                <div className="card-footer border-0 bg-white">
                    <div className="mb-1 mt-1">
                        <div className="row">
                            <div className='col-12'>
                                <div className='row p-1 d-flex justify-content-end'>
                                    { Estado ?
                                        <Link className="col-12 col-sm-6 col-md-2 custom-col">
                                            <button className="btn btn-success din-btn-style w-100" disabled><i className="fa-solid fa-check"></i>&nbsp;&nbsp;Guardar</button>
                                        </Link>
                                        :
                                        <Link onClick={FnActualizar} className="col-12 col-sm-6 col-md-2 custom-col">
                                            <button className="btn btn-success din-btn-style w-100"><i className="fa-solid fa-check"></i>&nbsp;&nbsp;Guardar</button>
                                        </Link>
                                    }
                                    <Link to={`/${nombre_controller}Detail/${id}`} className="col-12 col-sm-6 col-md-2 custom-col">
                                        <button className="btn btn-success din-btn-style w-100"><i className="fa-solid fa-eye"></i>&nbsp;&nbsp;Vista Preliminar</button>
                                    </Link>
                                    <Link to={`/${nombre_controller}List`} className="col-12 col-sm-6 col-md-2 custom-col">
                                        <button className="btn btn-secondary din-btn-style w-100"><i className="fa-solid fa-arrow-right-from-bracket"></i>&nbsp;&nbsp;Ir a Lista</button>
                                    </Link>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ========================== VENTA ========================== */}
            {/* Modal Agregar */}
            <>
                <Modal show={ShowAgregarVenta} onHide={CloseVentaModal} size="lg" >
                <Modal.Header closeButton style={{backgroundColor:'#198754', color:'White'}}>
                    <Modal.Title>Agregar venta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row p-2'>
                        <InputComboBoxBusquedaItem campo="" name="Iditem" obligatorio="" idempresa={Idempresa} colsm="12" colmd="12" url={url} 
                        onChange={(selectedValue, selectedCodigo, selectedText) => {setIditem(selectedValue);setItemcodigo(selectedCodigo);setItemdesc(selectedText);}}/>
                        <InputTextPrecioUnitario campo="Precio Unitario" name="PrecioUnitario" obligatorio="*" colsm="6" colmd="6" onChange={setPrecioUnitario}/>
                        <InputTextCantidad campo="Cantidad" name="Cantidad" obligatorio="*" colsm="6" colmd="6" onChange={setCantidadItem}/>
                        <InputComboBoxTributo value={TributoCodigo} campo="Tipo Operacion" name="TributoCodigo" obligatorio="*" colsm="12" colmd="12" onChange={setTributoCodigo}/>
                        {/* <InputComboBoxFormaPago value={Idformapago} campo="Forma pago" name="Idformapago" obligatorio="*" colsm="12" colmd="6" 
                                            onChange={(IdValue, TextValue) => {setIdformapago(IdValue);setTxtformapago(TextValue);}}/> */}
                    </div>
                </Modal.Body>
                <Modal.Footer className='col-12' style={{display:'block',justifyContent: 'flex-end'}}>
                    <div className='row mt-1'>
                        <div className="col-12 col-sm-6 col-md-3 p-1">
                            <Button variant="secondary" onClick={CloseVentaModal} className='w-100'>Cerrar</Button>
                        </div>
                        <div className="col-12 col-sm-6 col-md-3 p-1">
                            <Button variant="success" onClick={() => {FnAgregarVenta();}} className='w-100'>Agregar</Button>
                        </div>
                    </div>
                </Modal.Footer>
                </Modal>
            </>
            {/* Modal Eliminar*/}
            <>
                <Modal show={ShowRetirarVenta} onHide={CloseRetirarVentaModal}>
                <Modal.Header closeButton style={{backgroundColor:'#dc3545', color:'White'}}>
                    <Modal.Title>Retirar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Desea retirar esta venta del comprobante?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={CloseRetirarVentaModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={FnRetirarVenta}>
                        Retirar
                    </Button>
                </Modal.Footer>
                </Modal>
            </>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={4000} autohide className='text-white' 
                style={{ position: 'fixed', top: '20px',right: '20px', zIndex: 1000, background: `${toastBackground}`}}>
                <Toast.Header>
                    <strong className="me-auto">Notificación</strong>
                    <small></small>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
            <FooterView/>
        </div>
        </>
    );
}

export default ComprobanteUpd;    /* <<<<<< CAMBIO */

