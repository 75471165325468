import Button from 'react-bootstrap/Button';
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { InputText } from "../../componentes/editar/InputText";
// import { InputComboBoxText } from "../../componentes/editar/InputComboBoxText";
import { InputComboBoxGenero } from "../../componentes/editar/cliente/InputComboBoxGenero";
import { InputComboBoxTipoDocumento } from "../../componentes/editar/cliente/InputComboBoxTipoDocumento";
import { InputComboBoxRangoEdad } from "../../componentes/editar/cliente/InputComboBoxRangoEdad";
import { InputComboBoxEstadoCivil } from "../../componentes/editar/cliente/InputComboBoxEstadoCivil";
import { InputComboBoxParentesco } from "../../componentes/editar/cliente/InputComboBoxParentesco";
import { InputComboBoxTextId } from "../../componentes/editar/InputComboBoxTextId";
import { InputComboBoxBusquedaTextId } from "../../componentes/editar/InputComboBoxBusquedaTextId";
import { InputDate } from "../../componentes/editar/InputDate";
import { InputCheckBox } from "../../componentes/editar/InputCheckBox";
import { InputComboBoxN2TextId } from "../../componentes/editar/InputComboBoxN2TextId";
// import { InputComboBoxBusqueda } from "../../componentes/editar/InputComboBoxBusqueda";
// import { InputComboBoxN2 } from "../../componentes/editar/InputComboBoxN2";
import { InputAreaText } from "../../componentes/editar/InputAreaText";
import { Link } from "react-router-dom";
import Toast from 'react-bootstrap/Toast';
import Modal from 'react-bootstrap/Modal';
import  RouterView  from '../../componentes/layout/RouterView';
import { useNavigate } from 'react-router-dom';
import Sidebar from "../../pages/Sidebar";
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import '../../Assets/css/din_style_2.css'
import '../../Assets/css/StyleSlidebar.css'
import { useSelector } from 'react-redux';
import API_BASE_URL  from "../../config";

const nombre_controller = 'Clientes';           // <<<<<< CAMBIO
const url = `${API_BASE_URL}`;       // <<<<<< CAMBIO
const RutaHead = [
    { name: 'Cliente', ruta: `/${nombre_controller}List` },        // <<<<<< CAMBIO
    { name: 'Edición Cliente', ruta: `/${nombre_controller}Upd` }, // <<<<<< CAMBIO
];
function ClienteUpd() {     // <<<<<< CAMBIO
    //Redux
    const user = useSelector((state) => state.user);
    const [Idempresa, setIdempresa] = useState(user.companyId);
    const [Idusuario, setIdusuario] = useState(user.userId);
    // Función para actualizar el usuario en el estado local cuando cambie en Redux
    useEffect(() => {
        setIdempresa(user.companyId);
        setIdusuario(user.userId);
    }, [user]); 
    //Cliente
    const { id } = useParams();    
    const [Clinom, setClinom] = useState('');                   // <<<<<< CAMBIO
    const [Cliruc, setCliruc] = useState('');                   // <<<<<< CAMBIO
    const [Clitelf, setClitelf] = useState('');                 // <<<<<< CAMBIO
    const [Clidirec, setClidirec] = useState('');               // <<<<<< CAMBIO
    const [Cliciudad, setCliciudad] = useState('');             // <<<<<< CAMBIO
    const [Cliemail, setCliemail] = useState('');               // <<<<<< CAMBIO
    const [Cliobs, setCliobs] = useState('');                   // <<<<<< CAMBIO
    const [Iddepartamento, setIddepartamento] = useState('');   // <<<<<< CAMBIO
    const [Txtdepartamento, setTxtdepartamento] = useState(''); // <<<<<< CAMBIO
    const [Idprovincia, setIdprovincia] = useState('');         // <<<<<< CAMBIO
    const [Txtprovincia, setTxtprovincia] = useState('');       // <<<<<< CAMBIO
    const [Iddistrito, setIddistrito] = useState('');           // <<<<<< CAMBIO
    const [Txtdistrito, setTxtdistrito] = useState('');         // <<<<<< CAMBIO
    const [Txttipodocumento, setTxttipodocumento] = useState(''); // <<<<<< CAMBIO
    const [Idpais, setIdpais] = useState('');                   // <<<<<< CAMBIO
    const [Txtpais, setTxtpais] = useState('');                 // <<<<<< CAMBIO
    const [FechaNacimiento, setFechaNacimiento] = useState(''); // <<<<<< CAMBIO
    // const [Txtcargo, setTxtcargo] = useState('');       // <<<<<< CAMBIO
    const [Txtgenero, setTxtgenero] = useState('');       // <<<<<< CAMBIO
    const [Txtrangoedad, setTxtrangoedad] = useState('');       // <<<<<< CAMBIO
    const [Txtestadocivil, setTxtestadocivil] = useState('');   // <<<<<< CAMBIO
    const [Txtparentesco, setTxtparentesco] = useState('');     // <<<<<< CAMBIO
    const [Estado, setEstado] = useState('');                   // <<<<<< CAMBIO
    const ClearComboBox = () => {
        setIdprovincia(null)    // <<<<<< CAMBIO
        setIddistrito(null)     // <<<<<< CAMBIO
    }
    //TOAST
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBackground , setToastBackground ] = useState('#198754');
    const [valuesLoaded, setValuesLoaded] = useState(true);
    const FnActualizar = async () => {
        let mensaje='';
        if(Clinom.trim() !== '' && Cliruc.trim() !== '' && Txttipodocumento !== '' 
        && Idpais.toString().trim() !== '' && Txtgenero !== '' && Txtrangoedad !== '' 
        && Clidirec.trim() !== ''){     // <<<<<< CAMBIO
            let cant = Cliruc.toString().length;        // <<<<<< CAMBIO
            if(Txttipodocumento==='RUC' && cant !== 11){// <<<<<< CAMBIO
                mensaje =`Operación detenida. El RUC presenta ${cant} carácteres, debe tener 11 carácteres para el cliente.`;
                setToastMessage(mensaje);
                setShowToast(true);
                setToastBackground('#CC6868');
            }else if(Txttipodocumento==='DNI' && cant !== 8){
                mensaje =`Operación detenida. El DNI presenta ${cant} carácteres, debe tener 8 carácteres para el cliente.`;
                setToastMessage(mensaje);
                setShowToast(true);
                setToastBackground('#CC6868');
            }else{
                const now = new Date();
                const jsonData = {
                    Idcliente: id,      // <<<<<< CAMBIO
                    Clinom: Clinom,     // <<<<<< CAMBIO
                    Cliruc: Cliruc,     // <<<<<< CAMBIO
                    Clitelf: Clitelf,   // <<<<<< CAMBIO
                    Clidirec: Clidirec, // <<<<<< CAMBIO
                    Cliciudad:Cliciudad,// <<<<<< CAMBIO
                    Cliemail: Cliemail, // <<<<<< CAMBIO
                    Cliobs: Cliobs,     // <<<<<< CAMBIO
                    Txtdepartamento: Txtdepartamento,   // <<<<<< CAMBIO
                    Txtprovincia: Txtprovincia, // <<<<<< CAMBIO
                    Txtdistrito: Txtdistrito,   // <<<<<< CAMBIO
                    Iddistrito: Iddistrito,     // <<<<<< CAMBIO
                    Txttipodocumento: Txttipodocumento, // <<<<<< CAMBIO
                    Idpais: Idpais,     // <<<<<< CAMBIO
                    Txtpais: Txtpais,   // <<<<<< CAMBIO
                    Idempresa: Idempresa, // <<<<<< CAMBIO
                    FechaNacimiento: FechaNacimiento,   // <<<<<< CAMBIO
                    // Txtcargo: Txtcargo,     // <<<<<< CAMBIO
                    Txtgenero: Txtgenero,   // <<<<<< CAMBIO
                    Txtrangoedad: Txtrangoedad, // <<<<<< CAMBIO
                    Txtestadocivil: Txtestadocivil, // <<<<<< CAMBIO
                    Txtparentesco: Txtparentesco,   // <<<<<< CAMBIO
                    Estado: Estado ? 2 : 1, // <<<<<< CAMBIO
                    Fechact: now.toISOString(),
                    Codusuact: Idusuario,
                };
                const token = localStorage.getItem('token-dincors');
                try{
                    const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Update`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json; charset=UTF-8',
                            'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify(jsonData),
                    })
                    if(response.ok){
                        const data = await response.json();
                        if(data.res){
                            localStorage.setItem('toastMessage1', data.msg);
                            localStorage.setItem('toastBackground1', '#198754');
                            localStorage.setItem('idpage', id);
                            navigate(`/${nombre_controller}List`);
                        }else{
                            setToastMessage(data.msg);
                            setShowToast(true);
                            setToastBackground('#CC6868');
                        }
                    }
                } catch(error) {
                    setToastBackground('#CC6868');
                    setToastMessage(error.message);
                    setShowToast(true);
                };
            }
        }else{
            mensaje =`Operación detenida. Llene todos los campos obligatorios.`;
            setToastMessage(mensaje);
            setShowToast(true);
            setToastBackground('#CC6868');
        }
    };
    const token2 = localStorage.getItem('token-dincors');
    useEffect(() => {
        UbicarRegistro(id, token2, {
            setClinom,          // <<<<<< CAMBIO
            setCliruc,          // <<<<<< CAMBIO
            setClitelf,         // <<<<<< CAMBIO
            setClidirec,        // <<<<<< CAMBIO    
            setCliciudad,       // <<<<<< CAMBIO    
            setCliemail,        // <<<<<< CAMBIO    
            setCliobs,          // <<<<<< CAMBIO
            setTxtdepartamento, // <<<<<< CAMBIO        
            setTxtprovincia,    // <<<<<< CAMBIO        
            setIddistrito,      // <<<<<< CAMBIO    
            setTxtdistrito,     // <<<<<< CAMBIO    
            setTxttipodocumento,// <<<<<< CAMBIO            
            setIdpais,          // <<<<<< CAMBIO
            setTxtpais,         // <<<<<< CAMBIO
            setFechaNacimiento, // <<<<<< CAMBIO        
            // setTxtcargo,        // <<<<<< CAMBIO    
            setTxtgenero,       // <<<<<< CAMBIO    
            setTxtrangoedad,    // <<<<<< CAMBIO        
            setTxtestadocivil,  // <<<<<< CAMBIO        
            setTxtparentesco,   // <<<<<< CAMBIO        
            setEstado,          // <<<<<< CAMBIO
        })
        .then(function (data) {
            setValuesLoaded(true);
        })
        .catch(function (error) {
            console.log(error);
        });
        // eslint-disable-next-line
    }, [id]);
    useEffect(() => {
        if(Iddistrito){
            UbicarNiveles(Iddistrito, {
                setIddepartamento,  // <<<<<< CAMBIO
                setIdprovincia,     // <<<<<< CAMBIO
                setIddistrito,      // <<<<<< CAMBIO
            })
            .catch(function (error) {
                //console.log(error);
            });
        }
    }, [Iddistrito]);
    // ====================== MODAL ELIMINAR ======================
    // Agrega un estado para controlar la visibilidad del modal
    const navigate = useNavigate();
    const [showEliminar, setShowEliminar] = useState(false);
    const handleCloseEliminar = () => setShowEliminar(false);
    const handleShowEliminar = () => setShowEliminar(true);
    // Función de eliminar
    const FnEliminar = async() => {
        handleCloseEliminar();
        const jsonData = {
            Idcliente: id,          // <<<<<< CAMBIO
            Idempresa: Idempresa,   // <<<<<< CAMBIO
        };
        const token = localStorage.getItem('token-dincors');
        try {
            const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Delete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            })
            if(response.ok){                
                const data = await response.json();
                if(data.res){
                    localStorage.setItem('toastMessage1', data.msg);
                    localStorage.setItem('toastBackground1', '#198754');
                    navigate(`/${nombre_controller}List`);
                }else{
                    setToastMessage(data.msg);
                    setShowToast(true);
                    setToastBackground('#CC6868');
                }
            }
        }
        catch(error){
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        };
    };
    return (
        <>
        <HeadView/>
        <Sidebar />
        <div className="home-section">
            <RouterView RutaHead={RutaHead}></RouterView>
            <section className="container-fluid mt-3">
                <div className="card m-1">
                    {/* CARD HEAD */}
                    <h5 className="card-header din-card-header">DATOS GENERALES</h5>
                    {/* CARD BODY */}
                    <div className="card-body mx-2">
                        {valuesLoaded && (
                        <>
                            {/* Titulo */}
                            <div className="row mt-3">
                                <div className="col-12">
                                    <h5 className="card-title text-success">Datos Generales</h5>
                                    <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                                </div>
                            </div>
                            {/* Datos */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <InputText value={Clinom} campo="Cliente" name="Clinom" obligatorio="*" colsm="6" colmd="4" onChange={setClinom}/>  {/* <<<<<< CAMBIO */}
                                        <InputComboBoxTipoDocumento value={Txttipodocumento} campo="Tipo de Documento" name="Txttipodocumento" obligatorio="*" colsm="6" colmd="4" url={url} onChange={setTxttipodocumento}/> {/* <<<<<< CAMBIO */}
                                        <InputText value={Cliruc} campo="Numero de documento" name="Cliruc" obligatorio="*" colsm="6" colmd="4" onChange={setCliruc}/>  {/* <<<<<< CAMBIO */}
                                        <InputText value={Clitelf} campo="Telefono" name="Clitelf" obligatorio="" colsm="6" colmd="4" onChange={setClitelf}/>           {/* <<<<<< CAMBIO */}
                                        <InputComboBoxGenero value={Txtgenero} campo="Genero" name="Txtgenero" obligatorio="*" colsm="6" colmd="4" url={url} onChange={setTxtgenero}/> {/* <<<<<< CAMBIO */}
                                        <InputComboBoxRangoEdad value={Txtrangoedad} campo="Rango de Edad" name="Txtrangoedad" obligatorio="*" colsm="6" colmd="4" url={url} onChange={setTxtrangoedad}/> {/* <<<<<< CAMBIO */}
                                        <InputComboBoxBusquedaTextId value={Idpais} campo="Pais" name="Idpais" obligatorio="*" colsm="6" colmd="4" url={url} 
                                        onChange={(selectedValue, selectedText) => {setIdpais(selectedValue);setTxtpais(selectedText);}}/>      {/* <<<<<< CAMBIO */}
                                        <InputComboBoxTextId value={Iddepartamento} campo="Departamento" name="Iddepartamento" obligatorio="" colsm="6" colmd="4" url={url} 
                                        onChange={(selectedValue, selectedText) => {setIddepartamento(selectedValue);setTxtdepartamento(selectedText);ClearComboBox();}} />  {/* <<<<<< CAMBIO */}
                                        <InputComboBoxN2TextId value={Idprovincia} campo="Provincia" name="Idprovincia" obligatorio="" colsm="6" colmd="4" url={url} 
                                        onChange={(selectedValue, selectedText) => {setIdprovincia(selectedValue);setTxtprovincia(selectedText);}} idnivel={Iddepartamento}/>{/* <<<<<< CAMBIO */}
                                        <InputComboBoxN2TextId value={Iddistrito} campo="Distrito" name="Iddistrito" obligatorio="" colsm="6" colmd="4" url={url} 
                                        onChange={(selectedValue, selectedText) => {setIddistrito(selectedValue);setTxtdistrito(selectedText);}} idnivel={Idprovincia}/>    {/* <<<<<< CAMBIO */}
                                        <InputText value={Cliciudad} campo="Ciudad/Referencia" name="Cliciudad" obligatorio="" colsm="6" colmd="4" onChange={setCliciudad}/>{/* <<<<<< CAMBIO */}
                                        <InputAreaText value={Clidirec} campo="Direccion" name="Clidirec" obligatorio="*" colsm="12" onChange={setClidirec}/>               {/* <<<<<< CAMBIO */}
                                    </div>
                                </div>
                            </div>
                            {/* Titulo */}
                            <div className="row mt-3">
                                <div className="col-12">
                                    <h5 className="card-title text-success">Datos Complementarios</h5>
                                    <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                                </div>
                            </div>
                            {/* Datos */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <InputDate value={FechaNacimiento} campo="Fecha de Nacimiento" name="FechaNacimiento" obligatorio="" colsm="6" colmd="4" onChange={setFechaNacimiento}/>    {/* <<<<<< CAMBIO */}
                                        {/* <InputComboBoxText value={Txtcargo} campo="Cargo" name="Txtcargo" obligatorio="" colsm="6" colmd="4" url={url} onChange={setTxtcargo}/> */}
                                        <InputComboBoxEstadoCivil value={Txtestadocivil} campo="Estado Civil" name="Txtestadocivil" obligatorio="" colsm="6" colmd="4" url={url} onChange={setTxtestadocivil}/> {/* <<<<<< CAMBIO */}
                                        <InputComboBoxParentesco value={Txtparentesco} campo="Parentesco" name="Txtparentesco" obligatorio="" colsm="6" colmd="4" url={url} onChange={setTxtparentesco}/>   {/* <<<<<< CAMBIO */} 
                                        <InputText value={Cliemail} campo="Correo Electronico" name="Cliemail" obligatorio="" colsm="6" colmd="4" onChange={setCliemail}/>        {/* <<<<<< CAMBIO */}
                                        <InputCheckBox value={Estado} campo="" name="Estado" obligatorio="" colsm="6" colmd="4" mensaje="¿Cliente activo?" onChange={setEstado}/> {/* <<<<<< CAMBIO */}
                                        <InputAreaText value={Cliobs} campo="Observaciones" name="Cliobs" obligatorio="" colsm="12" onChange={setCliobs}/>  {/* <<<<<< CAMBIO */}
                                    </div>
                                </div>
                            </div>
                        </>
                        )}
                    </div>
                    {/* CARD FOOTER */}
                    <div className="card-footer border-0 bg-white">
                        <div className="mb-1 mt-1">
                            <div className="row">
                                <div className='col-12'>
                                    <div className='row p-1 d-flex justify-content-end'>
                                        <Link to={`/${nombre_controller}List`} className="col-12 col-sm-6 col-md-2 custom-col">
                                            <button className="btn btn-secondary din-btn-style w-100"><i className="fa-solid fa-arrow-right-from-bracket"></i>&nbsp;&nbsp;Ir a Lista</button>
                                        </Link>
                                        <Link onClick={FnActualizar} className="col-12 col-sm-6 col-md-2 custom-col">
                                            <button className="btn btn-success din-btn-style w-100"><i className="fa-solid fa-check"></i>&nbsp;&nbsp;Guardar</button>
                                        </Link>
                                        <Link onClick={handleShowEliminar} className="col-12 col-sm-6 col-md-2 custom-col">
                                            <button className="btn btn-danger din-btn-style w-100"><i className="fa-solid fa-trash"></i>&nbsp;&nbsp;Eliminar</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
            <Toast
                show={showToast}
                onClose={() => setShowToast(false)}
                delay={4000}
                autohide
                className='text-white'
                style={{
                    position: 'fixed',
                    top: '20px',
                    right: '20px',
                    zIndex: 1000,
                    background: `${toastBackground}`,
                }}
                >
                <Toast.Header>
                    <strong className="me-auto">Notificación</strong>
                    <small></small>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
            {/* Modal Eliminar */}
            <>
                <Modal show={showEliminar} onHide={handleCloseEliminar}>
                    <Modal.Header closeButton className="bg-danger text-white">
                        <Modal.Title>Eliminación</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>¿Desea eliminar este registro?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseEliminar}>
                        Cerrar
                        </Button>
                        <Button variant="danger" onClick={FnEliminar}>
                        Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
            <FooterView/>
        </div>
        </>
    );
}

async function UbicarRegistro(id, token2, setters) {
    try{
        const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Upd/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${token2}`
            },
            credentials: 'include',
        })
        if (response.ok) {
            const data = await response.json();
            if(data.res){
                const datos = data.datos;
                setters.setClinom(datos.clinom); /* <<<<<< CAMBIO */
                setters.setCliruc(datos.cliruc);    /* <<<<<< CAMBIO */
                setters.setClitelf(datos.clitelf);  /* <<<<<< CAMBIO */
                setters.setClidirec(datos.clidirec);    /* <<<<<< CAMBIO */
                setters.setCliciudad(datos.cliciudad);  /* <<<<<< CAMBIO */
                setters.setCliemail(datos.cliemail);    /* <<<<<< CAMBIO */
                setters.setCliobs(datos.cliobs);    /* <<<<<< CAMBIO */
                setters.setTxtdepartamento(datos.txtdepartamento);  /* <<<<<< CAMBIO */
                setters.setTxtprovincia(datos.txtprovincia);    /* <<<<<< CAMBIO */
                setters.setIddistrito(datos.iddistrito);    /* <<<<<< CAMBIO */
                setters.setTxtdistrito(datos.txtdistrito);  /* <<<<<< CAMBIO */
                setters.setTxttipodocumento(datos.txttipodocumento);    /* <<<<<< CAMBIO */
                setters.setIdpais(datos.idpais);    /* <<<<<< CAMBIO */
                setters.setTxtpais(datos.txtpais);  /* <<<<<< CAMBIO */
                setters.setFechaNacimiento(datos.fechanacimiento);  /* <<<<<< CAMBIO */
                // setters.setTxtcargo(datos.txtcargo);    /* <<<<<< CAMBIO */
                setters.setTxtgenero(datos.txtgenero);  /* <<<<<< CAMBIO */
                setters.setTxtrangoedad(datos.txtrangoedad);    /* <<<<<< CAMBIO */
                setters.setTxtestadocivil(datos.txtestadocivil);    /* <<<<<< CAMBIO */
                setters.setTxtparentesco(datos.txtparentesco);  /* <<<<<< CAMBIO */
                setters.setEstado(datos.estado===2 ? 1 : 0);    /* <<<<<< CAMBIO */
                return datos;
            }else{
                throw new Error(data.msg);
            }
        }else{
            throw new Error(`${response.status} ${response.statusText}`);
        }
    }
    catch (error) {
        throw error;
    };
} 

async function UbicarNiveles(id, setters) {
    try {
        const response = await fetch(`${url}/ComboBox/UbigeoReturn/${id}`, {
            method: 'POST',
            credentials: 'include',
        });
        if (response.ok) {
            const data = await response.json();
            if(data.res){
                const datos = data.datos;
                setters.setIddepartamento(datos.id3);/* <<<<<< CAMBIO */
                setters.setIdprovincia(datos.id2);  /* <<<<<< CAMBIO */
                setters.setIddistrito(datos.id1);   /* <<<<<< CAMBIO */
                return datos;
            }
            
        }else{
            throw new Error("Error en la respuesta del servidor");
        }
    } catch (error) {
      throw error;
    }
}

export default ClienteUpd;

