import { useState, useEffect } from "react";
import React from 'react'
import Select from 'react-select'

const cantidad = 15;    // <<<<<< CAMBIO
async function ListarCombobox(name, url, idempresa) {
    try {
        const jsonData = {
            Iditem:null,
            Idempresa:idempresa,
        };
        const response = await fetch(`${url}/ComboBox/${name}Combo`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify(jsonData),
        });
        if (!response.ok) {
            throw new Error("Error en la respuesta del servidor");
        }
        const data = await response.json();
        const datosTransformados = data.datos.map(item => ({
            value: item.id,
            codigo: item.codigo,
            label: item.nombre
        }));
        return datosTransformados;
    } catch (error) {
        //console.log(error);
        throw error;
    }
}

function InputComboBoxBusquedaProveedor(props) {
    //const [ListarDatos, setListarDatos] = useState([]);
    const [ListarDatosFiltrados, setListarDatosFiltrados] = useState([]);
    useEffect(() => {
        ListarCombobox(props.name, props.url, props.idempresa)
            .then(function (data) {
                //setListarDatos(data);
                setListarDatosFiltrados(data.slice(0, cantidad)); // Filtrar los primeros 20 registros
                return data;
            })
            .catch(function (error) {
                // console.log(error);
            });
    // eslint-disable-next-line
    }, [props.name, props.url]);
    let abortController;
    const handleChange = (inputValue, { action }) => {
        if (action === 'input-change') {
            // Cancelar la solicitud anterior si aún no ha sido completada
            if (abortController) {
                abortController.abort();
            }
            // Crear un nuevo controlador de aborto
            const newAbortController = new AbortController();
            abortController = newAbortController;
            const jsonData = {
                Iditem:inputValue,
                Idempresa:props.idempresa,
            };
            setTimeout(() => {
                fetch(`${props.url}/ComboBox/${props.name}Combo`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    signal: newAbortController.signal, // Asociar la señal de aborto con la solicitud
                    body: JSON.stringify(jsonData),
                })
                .then(function (response) {
                    if (!response.ok) {
                        throw new Error("Error en la respuesta del servidor");
                    }
                    return response.json();
                })
                .then(function (data) {
                    const datosTransformados = data.datos.map(item => ({
                        value: item.id,
                        codigo: item.codigo,
                        label: item.nombre
                    }));
                    setListarDatosFiltrados(datosTransformados.slice(0, cantidad));
                })
                .catch(function (error) {
                    if (error.name !== 'AbortError') {
                        throw error;
                    }
                });
            }, 300);
        }
    };
    const handleSelectChange = (value) => {
        const Iditem = value;
        const Itemdesc = ListarDatosFiltrados.find((dato) => dato.value === Iditem);
        props.onChange(Iditem, Itemdesc ? Itemdesc.codigo : "", Itemdesc ? Itemdesc.label : "");
    };
    // animacion select
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const customStyles = {
        control: (base) => ({
            ...base,
            zIndex: isSelectOpen ? 90 : 1,
        }),
        menu: (base) => ({
            ...base,
            zIndex: isSelectOpen ? 90 : 1,
        }),
    };
    return (
        <div className={`col-12 col-sm-${props.colsm} col-md-${props.colmd} mb-3`}>
            <label htmlFor={`txt${props.name}`} className="form-label">{props.campo}<span className="text-danger">{props.obligatorio}</span></label>
            <Select isClearable={true} options={ListarDatosFiltrados} id={`cbx${props.name}`} 
            onChange={(selectedOption) => {
                const selectedValue = selectedOption ? selectedOption.value : null;
                handleSelectChange(selectedValue);
            }}
            onInputChange={handleChange} menuIsOpen={isSelectOpen} onMenuOpen={() => setIsSelectOpen(true)}
            onMenuClose={() => setIsSelectOpen(false)} styles={customStyles}
            />
            <span id={`${props.name}Error`} className="text-danger"></span>
        </div>
    );
}

export {InputComboBoxBusquedaProveedor};
