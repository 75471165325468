// import Button from 'react-bootstrap/Button';
import { useState, useEffect } from "react";
import { InputText } from "../../componentes/editar/InputText";
import { InputTextMonto } from "../../componentes/agregar/alquiler/InputTextMonto";
import { InputTextAdelanto } from "../../componentes/agregar/alquiler/InputTextAdelanto";
// import { InputTextSearch } from "../../componentes/agregar/InputTextSearch";
import { InputComboBoxBusquedaTextId } from "../../componentes/editar/InputComboBoxBusquedaTextId";
import { InputComboBoxGenero } from "../../componentes/editar/cliente/InputComboBoxGenero";
import { InputComboBoxTipoDocumento } from "../../componentes/editar/cliente/InputComboBoxTipoDocumento";
import { InputComboBoxRangoEdad } from "../../componentes/editar/cliente/InputComboBoxRangoEdad";
import { InputAreaText } from "../../componentes/editar/InputAreaText";
// import { InputComboBoxText } from "../../componentes/editar/InputComboBoxText";
import { InputComboBoxTextId } from "../../componentes/editar/InputComboBoxTextId";
import { InputComboBoxN2TextId } from "../../componentes/editar/InputComboBoxN2TextId";
import { InputComboBoxEstadoCivil } from "../../componentes/editar/cliente/InputComboBoxEstadoCivil";
import { InputComboBoxParentesco } from "../../componentes/editar/cliente/InputComboBoxParentesco";
import { InputDate } from "../../componentes/editar/InputDate";
import { InputCheckBox } from "../../componentes/editar/InputCheckBox";
// calculo
import { InputComboBoxDuracion as InputComboBoxDuracionAdd } from "../../componentes/agregar/tarifa/InputComboBoxDuracion";
import { InputDateFechaFin } from "../../componentes/agregar/alquiler/InputDateFechaFin";
import { InputTimeHoraFin } from "../../componentes/agregar/alquiler/InputTimeHoraFin";
import { Link, useParams, useNavigate } from "react-router-dom";
import Toast from 'react-bootstrap/Toast';
import  RouterView  from '../../componentes/layout/RouterView';
import Sidebar from "../../pages/Sidebar";
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import '../../Assets/css/din_style_2.css';
import '../../Assets/css/StyleSlidebar.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Card from 'react-bootstrap/Card';
import { animated, useSpring } from 'react-spring';
import { useSelector } from 'react-redux';
import API_BASE_URL  from "../../config";

const nombre_controller = 'Alquileres';        // <<<<<< CAMBIO
const url = `${API_BASE_URL}`;       // <<<<<< CAMBIO
const RutaHead = [
    { name: 'Alquiler', ruta: `/${nombre_controller}List` },          // <<<<<< CAMBIO
    { name: 'Registro Alquiler', ruta: `/${nombre_controller}Add` },  // <<<<<< CAMBIO
];
function AlquilerAdd() {
    const { id } = useParams();
    // Redux
    const user = useSelector((state) => state.user);
    const [Idempresa, setIdempresa] = useState(user.companyId);
    const [Idusuario, setIdusuario] = useState(user.userId);
    // Función para actualizar el usuario en el estado local cuando cambie en Redux
    useEffect(() => {
        setIdempresa(user.companyId);
        setIdusuario(user.userId);
    }, [user]);
    const navigate = useNavigate();
    // Datos de alojamiento
    const [Txtduracion, setTxtduracion] = useState('');  // <<<<<< CAMBIO
    const [Cantidad, setCantidad] = useState(1);  // <<<<<< CAMBIO
    const [Monto, setMonto] = useState('');  // <<<<<< CAMBIO
    const [Adelanto, setAdelanto] = useState('');  // <<<<<< CAMBIO
    const [AlojCheckoutDate, setAlojCheckoutDate] = useState('');  // <<<<<< CAMBIO
    const [AlojCheckoutTime, setAlojCheckoutTime] = useState('');  // <<<<<< CAMBIO
    // Datos internos
    const [FechaInicioTemp, setFechaInicioTemp] = useState('');  // <<<<<< CAMBIO
    const [AlojDias, setAlojDias] = useState('');  // <<<<<< CAMBIO
    const [AlojHoras, setAlojHoras] = useState('');  // <<<<<< CAMBIO
    const [AlojMinutos, setAlojMinutos] = useState('');  // <<<<<< CAMBIO
    const [AlojSegundos, setAlojSegundos] = useState('');  // <<<<<< CAMBIO
    const [AlojCheckin, setAlojCheckin] = useState('');  // <<<<<< CAMBIO
    const [AlojCheckinDate, setAlojCheckinDate] = useState('');  // <<<<<< CAMBIO
    const [AlojCheckinTime, setAlojCheckinTime] = useState('');  // <<<<<< CAMBIO
    const [AlojCheckout, setAlojCheckout] = useState('');  // <<<<<< CAMBIO
    const [AlojDates, setAlojDates] = useState('');  // <<<<<< CAMBIO
    const [AlojDuracion, setAlojDuracion] = useState('');  // <<<<<< CAMBIO
    const [AlojCantidadDuracion, setAlojCantidadDuracion] = useState('');  // <<<<<< CAMBIO
    // Datos cliente
    const [Idcliente, setIdcliente] = useState('');             // <<<<<< CAMBIO
    const [Clinom, setClinom] = useState('');                   // <<<<<< CAMBIO
    const [Cliruc, setCliruc] = useState('');                   // <<<<<< CAMBIO
    const [Clitelf, setClitelf] = useState('');                 // <<<<<< CAMBIO
    const [Clidirec, setClidirec] = useState('');               // <<<<<< CAMBIO
    const [Cliciudad, setCliciudad] = useState('');             // <<<<<< CAMBIO
    const [Cliemail, setCliemail] = useState('');               // <<<<<< CAMBIO
    const [Cliobs, setCliobs] = useState('');                   // <<<<<< CAMBIO
    const [Iddepartamento, setIddepartamento] = useState('');   // <<<<<< CAMBIO
    const [Txtdepartamento, setTxtdepartamento] = useState(''); // <<<<<< CAMBIO
    const [Idprovincia, setIdprovincia] = useState('');         // <<<<<< CAMBIO
    const [Txtprovincia, setTxtprovincia] = useState('');       // <<<<<< CAMBIO
    const [Iddistrito, setIddistrito] = useState('');           // <<<<<< CAMBIO
    const [Txtdistrito, setTxtdistrito] = useState('');         // <<<<<< CAMBIO
    const [Txttipodocumento, setTxttipodocumento] = useState(''); // <<<<<< CAMBIO
    const [Idpais, setIdpais] = useState(194);                   // <<<<<< CAMBIO
    const [Txtpais, setTxtpais] = useState('PERU');                 // <<<<<< CAMBIO
    const [FechaNacimiento, setFechaNacimiento] = useState(''); // <<<<<< CAMBIO
    const [Txtgenero, setTxtgenero] = useState('');             // <<<<<< CAMBIO
    const [Txtrangoedad, setTxtrangoedad] = useState('');       // <<<<<< CAMBIO
    const [Txtestadocivil, setTxtestadocivil] = useState('');   // <<<<<< CAMBIO
    const [Txtparentesco, setTxtparentesco] = useState('');     // <<<<<< CAMBIO
    const [Estadocliente, setEstadocliente] = useState(1);      // <<<<<< CAMBIO
    // const ClearComboBox = () => {
    //     setIddepartamento(null)    // <<<<<< CAMBIO
    //     setIdprovincia(null)    // <<<<<< CAMBIO
    //     setIddistrito(null)     // <<<<<< CAMBIO
    // }
    const ClearComboBox2 = () => {
        setIdprovincia(null)    // <<<<<< CAMBIO
        setIddistrito(null)     // <<<<<< CAMBIO
    }
    // Procesar checkin
    const [loadingSearch, setLoadingSearch] = useState(false);
    const FnProcesarCheckin = async () => {
        let mensaje='';
        try{
            setLoadingSearch(true);
            if (AlojDates===null || (Array.isArray(AlojDates) && AlojDates.length === 0)) {
                throw new Error("Operacion Detenida. Debe ingresar al menos una hora o dia.");
            }
            if (Monto===null || Monto===0) {
                throw new Error("Por favor, ingrese el Precio");
            }
            if(Monto === 'NO AUTORIZADO'){
                throw new Error('No está autorizado para realizar esta operación');
            }
            if(Clinom.trim() !== '' && Cliruc.trim() !== '' && Txttipodocumento !== '' 
            && Idpais.toString().trim() !== '' && Txtgenero !== '' && Txtrangoedad !== '' 
            && Clidirec.trim() !== ''){
                let cant = Cliruc.toString().length;
                if(Txttipodocumento==='RUC' && cant !== 11){
                    mensaje =`Operación detenida. El RUC presenta ${cant} carácteres, debe tener 11 carácteres para el cliente.`;
                    throw new Error(mensaje);
                }else if(Txttipodocumento==='DNI' && cant !== 8){
                    mensaje =`Operación detenida. El DNI presenta ${cant} carácteres, debe tener 8 carácteres para el cliente.`;
                    throw new Error(mensaje);
                }else{
                    //console.log("paso!")
                    const token = localStorage.getItem('token-dincors');
                    const now = new Date();
                    const jsonData = {
                        // habitacion
                        Idhabitacion: id,
                        Idcategoria: dataDetalle.idcategoria,
                        Monto: Monto,
                        Adelanto: Adelanto,
                        AlojCheckoutDate: AlojCheckoutDate,
                        AlojCheckoutTime: AlojCheckoutTime,
                        AlojDuracion: AlojDuracion,
                        AlojCantidadDuracion: AlojCantidadDuracion,
                        AlojDias: AlojDias,
                        AlojHoras: AlojHoras,
                        AlojMinutos: AlojMinutos,
                        AlojSegundos: AlojSegundos,
                        AlojCheckin: AlojCheckin,
                        AlojCheckinDate: AlojCheckinDate,
                        AlojCheckinTime: AlojCheckinTime,
                        AlojCheckout: AlojCheckout,
                        AlojDates: AlojDates,
                        FechaInicioTemp: FechaInicioTemp,
                        // venta
                        Estadoven: "PENDIENTE",
                        Vennombre: "",
                        // pago
                        Tipopago: null,
                        Tipocomprobante: null,
                        Idestado: 1,
                        // cliente
                        Motivoviaje: null,
                        Idcliente: Idcliente,
                        Clinom: Clinom,
                        Cliruc: Cliruc,
                        Clitelf: Clitelf,
                        Clidirec: Clidirec,
                        Cliciudad: Cliciudad,
                        Cliemail: Cliemail,
                        Cliobs: Cliobs,
                        Txtdepartamento: Txtdepartamento,
                        Txtprovincia: Txtprovincia,
                        Txtdistrito: Txtdistrito,
                        Iddistrito: Iddistrito,
                        Txttipodocumento: Txttipodocumento,
                        Idpais: Idpais,
                        Txtpais: Txtpais,
                        Idempresa: Idempresa,
                        FechaNacimiento: FechaNacimiento,
                        Txtgenero: Txtgenero,
                        Txtrangoedad: Txtrangoedad,
                        Txtestadocivil: Txtestadocivil,
                        Txtparentesco: Txtparentesco,
                        Estado: Estadocliente ? 2 : 1,
                        Fechreg: now.toISOString(),
                        Fechact: now.toISOString(),
                        Codusureg: Idusuario,
                        Codusuact: Idusuario,
                    };
                    console.log("info: ", jsonData)
                    try {
                        const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Create`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json; charset=UTF-8',
                                'Authorization': `Bearer ${token}`
                            },
                            body: JSON.stringify(jsonData),
                        });
                        if(response.ok){                
                            const data = await response.json();
                            if(data.res){
                                setToastBackground('#198754');
                                setToastMessage(data.msg);
                                setShowToast(true);
                                localStorage.setItem('toastMessage2', data.msg);
                                localStorage.setItem('toastBackground2', '#198754');
                                navigate(`/${nombre_controller}Upd/${dataDetalle.idhabitacion}/${data.idalquiler}`);
                            }else{
                                throw new Error(data.msg);
                            }
                        }else  {
                            throw new Error(`${response.status} ${response.statusText}`);
                        }
                    }catch (error){
                        setToastBackground('#CC6868');
                        setToastMessage(error.message);
                        setShowToast(true);
                    }
                }
            }else{
                mensaje =`Operación detenida. Llene todos los campos obligatorios.`;
                throw new Error(mensaje);
            }
        }catch (error){
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        } finally {
            setTimeout(() => {
                setLoadingSearch(false);
            }, 800); 
        }
    };
    // Detalle Habitacion
    const [dataDetalle, setDataDetalle] = useState([]);
    const [loadingDetalle, setLoadingDetalle]=useState(false);
    const [AlojCheckinFormat, setAlojCheckinFormat] = useState('');  // <<<<<< CAMBIO
    const [AlojCheckoutFormat, setAlojCheckoutFormat] = useState('');  // <<<<<< CAMBIO
    useEffect(() => {
        const DataDetalle = async ()=>{
            setLoadingDetalle(true);
            const token = localStorage.getItem('token-dincors');
            try {
                const response = await fetch(`${url}/Habitaciones/HabitacionesDetail/${id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${token}`
                    },
                });
                if(response.ok){
                    const data = await response.json();
                    if(data.res){
                        setDataDetalle(data.datos);
                    }else{
                        throw new Error(data.msg);
                    }
                }else{
                    throw new Error(`${response.status} ${response.statusText}`);
                }
            }catch (error) {
                setToastBackground('#CC6868');
                setToastMessage(error.message);
                setShowToast(true);
            }finally{
                setLoadingDetalle(false);
            }
        }
        DataDetalle();
    }, [id]);
    // ====================== MODAL DETALLE TARIFA ======================  
    const [ShowDetalleTarifa, setShowDetalleTarifa] = useState(false);
    const CloseDetalleTarifa = () => setShowDetalleTarifa(false);
    const [DataDetalleTarifa, setDataDetalleTarifa]=useState([]);
    const handleShowDetalleTarifa = () => {
        // if(AlojDuracion !== '03 DIAS AUTORIZAR' && AlojDuracion !== '04 HORAS AUTORIZAR'){
        setShowDetalleTarifa(true);
        // }
    };
    // TARIFA DETALLE - Función para reducir o aumentar en 5
    const reducirTarifa = (index) => {
        setDataDetalleTarifa((prevData) => {
        const newData = [...prevData];
        newData[index] = {
            ...newData[index],
            tarifa: parseFloat(newData[index].tarifa) - 5
        };
        return newData;
        });
    };
    const aumentarTarifa = (index) => {
        setDataDetalleTarifa((prevData) => {
        const newData = [...prevData];
        newData[index] = {
            ...newData[index],
            tarifa: parseFloat(newData[index].tarifa) + 5
        };
        return newData;
        });
    };
    const handleInputChange = (e, index) => {
        const newDetalleTarifa = [...DataDetalleTarifa];
        const inputValue = e.target.value.trim();
        if (/^\d*\.?\d*$/.test(inputValue)) {
            if (inputValue !== "") {
                newDetalleTarifa[index].tarifa = parseFloat(inputValue);
            } else {
                newDetalleTarifa[index].tarifa = 0; // O cualquier otro valor predeterminado cuando el campo está vacío
            }
        }else{
            newDetalleTarifa[index].tarifa = 0;
        }
        // if (inputValue !== "") {
        //     newDetalleTarifa[index].tarifa = parseFloat(inputValue);
        // } else {
        //     newDetalleTarifa[index].tarifa = null; // O cualquier otro valor predeterminado cuando el campo está vacío
        // }
        //newDetalleTarifa[index].tarifa = parseFloat(e.target.value);
        setDataDetalleTarifa(newDetalleTarifa);
    };
    // Mostrar u ocultar segun el tipo de duracion
    const [showButton, setShowButton] = useState(false);
    // Función para confirmar calculo para 3 y 4
    const FnOkCalcularTarifa= async() => {
        setAlojDates(DataDetalleTarifa);
        const totalTarifas = DataDetalleTarifa.reduce((sum, detalle) => sum + parseFloat(detalle.tarifa), 0);
        setMonto(totalTarifas);
        setAdelanto(0);
        setShowDetalleTarifa(false);
        if(RecalcularTarifa){
            FnReCalcularAlquiler();
            setRecalcularTarifa(false);
        }
    };
    // Calculo general
    const FnCalcularAlquiler = async () => {
        const token = localStorage.getItem('token-dincors');
        const jsonData = {
            Duracion: Txtduracion,
            Cantidad: Cantidad,
            Idcategoria: dataDetalle.idcategoria,
            Idempresa: Idempresa,
            Accion: 'ADD',
        };
        try {
            const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Calcular`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            });
            if(response.ok){                
                const data = await response.json();
                console.log(data)
                if(data.res){
                    // if((data.datos.aloj_duracion === '03 DIAS AUTORIZAR' && parseInt(data.datos.aloj_dia_autorizar,10) === 0) || (data.datos.aloj_duracion === '04 HORAS AUTORIZAR' && parseInt(data.datos.aloj_hora_autorizar,10) === 0)){
                        // Detalle tarifa - Modal
                        setShowDetalleTarifa(true);
                        setAlojCheckinFormat(data.datos.aloj_checkin_format)
                        setAlojCheckoutFormat(data.datos.aloj_checkout_format)
                        // setDataDetalleTarifa(data.datos.aloj_dates)
                        setDataDetalleTarifa(data.datos.aloj_dates.map(subarray => {
                            return {
                              fecha: subarray[0],
                              tarifa: parseFloat(subarray[1]),
                              fechaFormateada: subarray[2]
                            };
                          }));
                        // ========= btn-ok-calcularTarifa =========
                            // Datos calculo
                            setAlojCheckoutDate(data.datos.aloj_checkout_date);
                            setAlojCheckoutTime(data.datos.aloj_checkout_time);
                            // Datos internos
                            setFechaInicioTemp(data.datos.aloj_checkin);
                            setAlojDias(data.datos.aloj_dias);
                            setAlojHoras(data.datos.aloj_horas);
                            setAlojMinutos(data.datos.aloj_minutos);
                            setAlojSegundos(data.datos.aloj_segundos);
                            setAlojCheckin(data.datos.aloj_checkin);
                            setAlojCheckinDate(data.datos.aloj_checkin_date);
                            setAlojCheckinTime(data.datos.aloj_checkin_time);
                            setAlojCheckout(data.datos.aloj_checkout);
                            
                            setAlojDuracion(data.datos.aloj_duracion);
                            setAlojCantidadDuracion(data.datos.aloj_cantidad);
                        // ========= btn-ok-calcularTarifa =========
                        setShowButton(true);
                        // Datos calculado
                            //setAlojDates(data.datos.aloj_dates);
                            
                            //setMonto(data.datos.aloj_monto);
                            // toggle de modal
                        // ========= btn ok calcular tarifa =========
                    // }else if((data.datos.aloj_duracion === '03 DIAS AUTORIZAR' && data.datos.aloj_dia_autorizar === null) || (data.datos.aloj_duracion === '04 HORAS AUTORIZAR' && data.datos.aloj_hora_autorizar === null)){
                    //     setMonto('NO AUTORIZADO');
                    // }else{
                    //     // Datos calculo
                    //     setMonto(data.datos.aloj_monto);
                    //     setAdelanto(0);
                    //     setAlojCheckoutDate(data.datos.aloj_checkout_date);
                    //     setAlojCheckoutTime(data.datos.aloj_checkout_time);
                    //     // Datos internos
                    //     setAlojCheckinFormat(data.datos.aloj_checkin_format)
                    //     setAlojCheckoutFormat(data.datos.aloj_checkout_format)
                    //     setFechaInicioTemp(data.datos.aloj_checkin);
                    //     setAlojDias(data.datos.aloj_dias);
                    //     setAlojHoras(data.datos.aloj_horas);
                    //     setAlojMinutos(data.datos.aloj_minutos);
                    //     setAlojSegundos(data.datos.aloj_segundos);
                    //     setAlojCheckin(data.datos.aloj_checkin);
                    //     setAlojCheckinDate(data.datos.aloj_checkin_date);
                    //     setAlojCheckinTime(data.datos.aloj_checkin_time);
                    //     setAlojCheckout(data.datos.aloj_checkout);
                    //     setAlojDates(data.datos.aloj_dates);
                    //     setAlojDuracion(data.datos.aloj_duracion);
                    //     setAlojCantidadDuracion(data.datos.aloj_cantidad);
                    //     setDataDetalleTarifa(data.datos.aloj_dates.map(subarray => {
                    //         return {
                    //           fecha: subarray[0],
                    //           tarifa: parseFloat(subarray[1]),
                    //           fechaFormateada: subarray[2]
                    //         };
                    //     }));
                    //     setShowButton(false);
                    // }
                    setToastBackground('#198754');
                    setToastMessage(data.msg);
                    setShowToast(true);
                }else{
                    setToastMessage(data.msg);
                    setShowToast(true);
                    setToastBackground('#CC6868');
                }
            }else  {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }catch (error){
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }
    };
    // Calculo general
    const FnReCalcularAlquiler = async () => {
        const jsonData = {
            Duracion: Txtduracion,
            Cantidad: Cantidad,
            Idcategoria: dataDetalle.idcategoria,
            Idempresa: Idempresa,
            Accion: 'ADD',
        };
        const token = localStorage.getItem('token-dincors');
        try {
            const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Calcular`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            });
            if(response.ok){                
                const data = await response.json();
                console.log(data)
                if(data.res){
                    setAlojCheckinFormat(data.datos.aloj_checkin_format)
                    setAlojCheckoutFormat(data.datos.aloj_checkout_format)
                    // ========= btn-ok-calcularTarifa =========
                        // Datos calculo
                        setAlojCheckoutDate(data.datos.aloj_checkout_date);
                        setAlojCheckoutTime(data.datos.aloj_checkout_time);
                        // Datos internos
                        setFechaInicioTemp(data.datos.aloj_checkin);
                        setAlojDias(data.datos.aloj_dias);
                        setAlojHoras(data.datos.aloj_horas);
                        setAlojMinutos(data.datos.aloj_minutos);
                        setAlojSegundos(data.datos.aloj_segundos);
                        setAlojCheckin(data.datos.aloj_checkin);
                        setAlojCheckinDate(data.datos.aloj_checkin_date);
                        setAlojCheckinTime(data.datos.aloj_checkin_time);
                        setAlojCheckout(data.datos.aloj_checkout);
                        setAlojDuracion(data.datos.aloj_duracion);
                        setAlojCantidadDuracion(data.datos.aloj_cantidad);
                    // ========= btn-ok-calcularTarifa =========
                    setToastBackground('#198754');
                    setToastMessage(data.msg);
                    setShowToast(true);
                }else{
                    setToastMessage(data.msg);
                    setShowToast(true);
                    setToastBackground('#CC6868');
                }
            }else  {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }catch (error){
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }
    };
    // Quitar fecha
    const [RecalcularTarifa, setRecalcularTarifa] = useState(false);
    const FnDeleteCantidadTarifa = async (index) => {
        const newDataDetalleTarifa = DataDetalleTarifa.filter((_, i) => i !== index);
        setDataDetalleTarifa(newDataDetalleTarifa);
        // Reducir en 1 la variable Cantidad
        setCantidad((prevCantidad) => prevCantidad - 1);
        setRecalcularTarifa(true);
        // setCantidad((prevCantidad) => prevCantidad - 1, () => {
        //     // Llamar a la función FnCalcularAlquiler con el nuevo valor de Cantidad
        //     FnCalcularAlquiler(index);
        // });
    };
    // Buscar Cliente
    const handleInputDocumentChange = (e) => {
        const textValue = e.target.value;
        setCliruc(textValue)
    };
    const handleSearchCliente = async () => {
        try {
            const token = localStorage.getItem('token-dincors');
            const jsonData = {
                Cliruc: Cliruc ? Cliruc : null,//${}
                Idempresa: Idempresa,
            };
            const response = await fetch(`${url}/Clientes/ClientesSearch`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            });
            if(response.ok){                
                const data = await response.json();
                if(data.res){
                    setIdcliente(data.datos.idcliente);          // <<<<<< CAMBIO
                    setClinom(data.datos.clinom);          // <<<<<< CAMBIO
                    setCliruc(data.datos.cliruc);          // <<<<<< CAMBIO
                    setClitelf(data.datos.clitelf);         // <<<<<< CAMBIO
                    setClidirec(data.datos.clidirec);        // <<<<<< CAMBIO    
                    setCliciudad(data.datos.cliciudad);       // <<<<<< CAMBIO    
                    setCliemail(data.datos.cliemail);        // <<<<<< CAMBIO    
                    setCliobs(data.datos.cliobs);          // <<<<<< CAMBIO
                    setTxtdepartamento(data.datos.txtdepartamento); // <<<<<< CAMBIO        
                    setTxtprovincia(data.datos.txtprovincia);    // <<<<<< CAMBIO        
                    setIddistrito(data.datos.iddistrito);      // <<<<<< CAMBIO    
                    setTxtdistrito(data.datos.txtdistrito);     // <<<<<< CAMBIO    
                    setTxttipodocumento(data.datos.txttipodocumento);// <<<<<< CAMBIO            
                    setIdpais(data.datos.idpais);          // <<<<<< CAMBIO
                    setTxtpais(data.datos.txtpais);         // <<<<<< CAMBIO
                    setFechaNacimiento(data.datos.fechanacimiento); // <<<<<< CAMBIO        
                    setTxtgenero(data.datos.txtgenero);       // <<<<<< CAMBIO    
                    setTxtrangoedad(data.datos.txtrangoedad);    // <<<<<< CAMBIO        
                    setTxtestadocivil(data.datos.txtestadocivil);  // <<<<<< CAMBIO        
                    setTxtparentesco(data.datos.txtparentesco);   // <<<<<< CAMBIO        
                    setEstadocliente(data.datos.estado===2 ? 1 : 0);          // <<<<<< CAMBIO
                    setToastBackground('#198754');
                    setToastMessage(data.msg);
                    setShowToast(true);
                }else{
                    FnLimpiarDatos();
                    setToastMessage(data.msg);
                    setShowToast(true);
                    setToastBackground('#CC6868');
                }
            }else {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }catch (error){
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }
    };
    // Buscar Incidente
    const [SearchIncidente, setSearchIncidente] = useState('');  
    const [DataIncidentes, setDataIncidentes]=useState([]);
    const handleSearchIncidente = async () => {
        try {
            const token = localStorage.getItem('token-dincors');
            const jsonData = {
                Cliente: SearchIncidente,
                Idempresa: Idempresa,
            };
            const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}Incidentes`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            });
            if(response.ok){                
                const data = await response.json();
                console.log("inc: ", data)
                if(data.res){
                    setDataIncidentes(data.datos)
                    setToastBackground('#198754');
                    setToastMessage(data.msg);
                    setShowToast(true);
                }else{
                    throw new Error(data.msg);
                }
            }else  {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }catch (error){
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }
    };
    // detalle alquileres incidente
    const token2 = localStorage.getItem('token-dincors');
    const [showDetalle, setShowDetalle] = useState(false);
    const CloseDetalle = () => setShowDetalle(false);
    const [dataDetalleAlquiler, setDataDetalleAlquiler] = useState([]);
    const [DataDetalleHuespedes, setDataDetalleHuespedes]=useState([]);
    const handleShowDetalle = async(itemId) => {
        setShowDetalle(true);
        try{
          const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}HistorialDetail/${itemId}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${token2}`
              },
              credentials: 'include',
          })
          if(response.ok) {
              const data = await response.json();
              console.log("det: ",data)
              if(data.res){
                  setDataDetalleAlquiler(data.datos);
                  setDataDetalleHuespedes(data.huesped);
              }else{
                  throw new Error(data.msg);
              }
          }else{
              throw new Error(`${response.status} ${response.statusText}`);
          }
        }catch (error){
            throw error;
        }
    };
    
    useEffect(() => {
        if(Iddistrito){
            UbicarNiveles(Iddistrito, {
                setIddepartamento,  // <<<<<< CAMBIO
                setIdprovincia,     // <<<<<< CAMBIO
                setIddistrito,      // <<<<<< CAMBIO
            })
            .catch(function (error) {
                //console.log(error);
            });
        }
    }, [Iddistrito]);
    // ====================== LIMPIAR CONSTANTES ======================
    const FnLimpiarDatos = () => {
        setClinom('');          // <<<<<< CAMBIO
        //setCliruc('');          // <<<<<< CAMBIO
        setClitelf('');         // <<<<<< CAMBIO
        setClidirec('');        // <<<<<< CAMBIO    
        setCliciudad('');       // <<<<<< CAMBIO    
        setCliemail('');        // <<<<<< CAMBIO    
        setCliobs('');          // <<<<<< CAMBIO
        setIddepartamento('');      // <<<<<< CAMBIO    
        setTxtdepartamento(''); // <<<<<< CAMBIO
        setIdprovincia('');      // <<<<<< CAMBIO    
        setTxtprovincia('');    // <<<<<< CAMBIO        
        setIddistrito('');      // <<<<<< CAMBIO    
        setTxtdistrito('');     // <<<<<< CAMBIO    
        setTxttipodocumento('');// <<<<<< CAMBIO            
        setIdpais(194);          // <<<<<< CAMBIO
        setTxtpais('PERU');         // <<<<<< CAMBIO
        setFechaNacimiento(''); // <<<<<< CAMBIO        
        setTxtgenero('');       // <<<<<< CAMBIO    
        setTxtrangoedad('');    // <<<<<< CAMBIO        
        setTxtestadocivil('');  // <<<<<< CAMBIO        
        setTxtparentesco('');   // <<<<<< CAMBIO        
        setEstadocliente(1);          // <<<<<< CAMBIO
    }
    //TOAST
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBackground , setToastBackground ] = useState('#198754');
    //Animacion al cargar
    const { opacity } = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        config: { duration: 200 },
    });
    return (
        <>
            <HeadView/>
            <Sidebar />
            <animated.div className="home-section" style={{opacity: opacity}}>
                <RouterView RutaHead={RutaHead}></RouterView>
                {/* DETALLE HABITACION */}
                <div className="row">
                    <div className="col-12">
                        <div className="card m-3">
                            <h5 className="card-header din-card-header">DATOS DE LA HABITACION</h5>
                            <div className="card-body mx-2">
                                {/* DETALLE */}
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-2">
                                        {/* Datos */}
                                        <div className="row">
                                            <div className="col-12">
                                            {loadingDetalle ? (
                                                <p><i className="fa-solid fa-spinner fa-spin-pulse fa-lg"></i>&nbsp;&nbsp;Cargando datos..</p>
                                            ) : (
                                                <div className="row">
                                                    <div className={`col-12 col-sm-12 col-md-6 mb-1`}>
                                                        <label className="form-label fw-bold">Nombre:&nbsp;&nbsp;</label>{dataDetalle.numero}
                                                    </div>
                                                    <div className={`col-12 col-sm-12 col-md-6 mb-1`}>
                                                        <label className="form-label fw-bold">Categoria:&nbsp;&nbsp;</label>{dataDetalle.categoria}
                                                    </div>
                                                    <div className={`col-12 col-sm-12 col-md-6 mb-1`}>
                                                        <label className="form-label fw-bold">Detalle:&nbsp;&nbsp;</label>{dataDetalle.detalle}
                                                    </div>
                                                    <div className={`col-12 col-sm-12 col-md-6 mb-1`}>
                                                        <label className="form-label fw-bold">Nivel:&nbsp;&nbsp;</label>{dataDetalle.nivel}
                                                    </div>
                                                    <div className={`col-12 col-sm-12 col-md-6 mb-1`}>
                                                        <label className="form-label fw-bold">Cantidad de personas permitidas:&nbsp;&nbsp;</label>{dataDetalle.cantidad_personas}
                                                    </div>
                                                    <div className={`col-12 col-sm-12 col-md-6 mb-1`}>
                                                        <label className="form-label fw-bold">Estado:&nbsp;&nbsp;</label>
                                                        <div className={`texto-small badge ${{1: 'bg-success',2: 'bg-danger',3: 'bg-warning',}[dataDetalle.idestado]}`}>
                                                            {dataDetalle.estado}
                                                        </div>
                                                    </div>
                                                </div>     
                                            )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
                {/* DATOS ALQUILER*/}
                <div className="row">
                    <div className="col-12">
                        <div className="card m-3">
                            <h5 className="card-header din-card-header">DATOS DE ALQUILER</h5>
                            {loadingSearch ? (
                                <div className='w-100' style={{ height: '700px', display: 'flex', alignItems: 'center', justifyContent: 'center',color:'#198754'}}>
                                    <i className="fa-solid fa-spinner fa-spin-pulse fa-lg" style={{fontSize:'60px'}}></i>
                                    &nbsp;&nbsp;<h1>Cargando datos...</h1>
                                </div>
                            ) : (
                                <>
                                    <div className="card-body mx-2">
                                        <div className="row">
                                            {/* CLIENTE */}
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 p-4">
                                                {/* Titulo */}
                                                <div className="row mt-2">
                                                    <div className="col-12">
                                                        <h5 className="card-title text-success">Datos del cliente</h5>
                                                        <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                                                    </div>
                                                </div>
                                                {/* Datos */}
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <InputText value={Clinom} campo="Cliente" name="Clinom" obligatorio="*" colsm="6" colmd="6" onChange={setClinom}/>        {/* <<<<<< CAMBIO */}
                                                            <InputComboBoxTipoDocumento value={Txttipodocumento} campo="Tipo de Documento" name="Txttipodocumento" obligatorio="*" colsm="6" colmd="6" onChange={setTxttipodocumento}/>         {/* <<<<<< CAMBIO */}
                                                            <div className={`col-12 col-sm-6 col-md-6 mb-3`}>
                                                                <label htmlFor={`txtCliruc`} className="form-label">Numero de documento<span className="text-danger">*</span></label>
                                                                <div className="input-group">
                                                                    <button className="btn btn-outline-success" onClick={handleSearchCliente}><i className="fa-solid fa-search"></i></button>
                                                                    <input id={`txtCliruc`} className="form-control" onChange={handleInputDocumentChange}/>
                                                                </div>
                                                            </div>
                                                            {/* <InputTextSearch campo="Numero de documento" name="Cliruc" obligatorio="*" colsm="6" colmd="6" onChange={setCliruc}/> */}
                                                            <InputComboBoxGenero value={Txtgenero} campo="Genero" name="Txtgenero" obligatorio="*" colsm="6" colmd="6" onChange={setTxtgenero}/> 
                                                            <InputComboBoxRangoEdad value={Txtrangoedad} campo="Rango de Edad" name="Txtrangoedad" obligatorio="*" colsm="6" colmd="6" onChange={setTxtrangoedad}/> 
                                                            <InputComboBoxBusquedaTextId value={Idpais} campo="Pais" name="Idpais" obligatorio="*" colsm="6" colmd="6" url={url} 
                                                            onChange={(selectedValue, selectedText) => {setIdpais(selectedValue);setTxtpais(selectedText);}}/>         {/* <<<<<< CAMBIO */}
                                                            <InputComboBoxTextId value={Iddepartamento} campo="Departamento" name="Iddepartamento" obligatorio="" colsm="6" colmd="6" url={url} 
                                                            onChange={(selectedValue, selectedText) => {setIddepartamento(selectedValue);setTxtdepartamento(selectedText);ClearComboBox2();}} />  {/* <<<<<< CAMBIO */}
                                                            <InputComboBoxN2TextId value={Idprovincia} campo="Provincia" name="Idprovincia" obligatorio="" colsm="6" colmd="6" url={url} 
                                                            onChange={(selectedValue, selectedText) => {setIdprovincia(selectedValue);setTxtprovincia(selectedText);}} idnivel={Iddepartamento}/>{/* <<<<<< CAMBIO */}
                                                            <InputComboBoxN2TextId value={Iddistrito} campo="Distrito" name="Iddistrito" obligatorio="" colsm="6" colmd="6" url={url} 
                                                            onChange={(selectedValue, selectedText) => {setIddistrito(selectedValue);setTxtdistrito(selectedText);}} idnivel={Idprovincia}/>    {/* <<<<<< CAMBIO */}
                                                            <InputComboBoxEstadoCivil value={Txtestadocivil} campo="Estado Civil" name="Txtestadocivil" obligatorio="" colsm="6" colmd="6" url={url} onChange={setTxtestadocivil}/> {/* <<<<<< CAMBIO */}
                                                            <InputAreaText value={Clidirec} campo="Direccion" name="Clidirec" obligatorio="*" colsm="12" onChange={setClidirec}/>        {/* <<<<<< CAMBIO */}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Titulo */}
                                                <div className="row mt-2">
                                                    <div className="col-12">
                                                        <h5 className="card-title text-success">Datos complementarios</h5>
                                                        <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <InputText value={Clitelf} campo="Telefono" name="Clitelf" obligatorio="" colsm="6" colmd="6" onChange={setClitelf}/>           {/* <<<<<< CAMBIO */}
                                                            <InputText value={Cliciudad} campo="Ciudad/Referencia" name="Cliciudad" obligatorio="" colsm="6" colmd="6" onChange={setCliciudad}/>{/* <<<<<< CAMBIO */}
                                                            <InputDate value={FechaNacimiento} campo="Fecha de Nacimiento" name="FechaNacimiento" obligatorio="" colsm="6" colmd="6" onChange={setFechaNacimiento}/>    {/* <<<<<< CAMBIO */}
                                                            <InputComboBoxParentesco value={Txtparentesco} campo="Parentesco" name="Txtparentesco" obligatorio="" colsm="6" colmd="6" url={url} onChange={setTxtparentesco}/>   {/* <<<<<< CAMBIO */} 
                                                            <InputText value={Cliemail} campo="Correo Electronico" name="Cliemail" obligatorio="" colsm="6" colmd="6" onChange={setCliemail}/>        {/* <<<<<< CAMBIO */}
                                                            <InputCheckBox value={Estadocliente} campo="" name="Estadocliente" obligatorio="" colsm="6" colmd="6" mensaje="¿Cliente activo?" onChange={setEstadocliente}/> {/* <<<<<< CAMBIO */}
                                                            <InputAreaText value={Cliobs} campo="Observaciones" name="Cliobs" obligatorio="" colsm="12" onChange={setCliobs}/>  {/* <<<<<< CAMBIO */}
                                                        </div>
                                                        {/* <Accordion defaultActiveKey="0">
                                                            <Accordion.Item eventKey="0">
                                                                <Accordion.Header>Datos Complementarios</Accordion.Header>
                                                                <Accordion.Body>
                                                                    <div className="row">
                                                                        <InputText value={Clitelf} campo="Telefono" name="Clitelf" obligatorio="" colsm="6" colmd="6" onChange={setClitelf}/>           
                                                                        <InputText value={Cliciudad} campo="Ciudad/Referencia" name="Cliciudad" obligatorio="" colsm="6" colmd="6" onChange={setCliciudad}/>
                                                                        <InputDate value={FechaNacimiento} campo="Fecha de Nacimiento" name="FechaNacimiento" obligatorio="" colsm="6" colmd="6" onChange={setFechaNacimiento}/>    
                                                                        <InputComboBoxText value={Txtcargo} campo="Cargo" name="Txtcargo" obligatorio="" colsm="6" colmd="6" url={url} onChange={setTxtcargo}/>         
                                                                        <InputComboBoxEstadoCivil value={Txtestadocivil} campo="Estado Civil" name="Txtestadocivil" obligatorio="" colsm="6" colmd="6" url={url} onChange={setTxtestadocivil}/> 
                                                                        <InputComboBoxParentesco value={Txtparentesco} campo="Parentesco" name="Txtparentesco" obligatorio="" colsm="6" colmd="6" url={url} onChange={setTxtparentesco}/>    
                                                                        <InputText value={Cliemail} campo="Correo Electronico" name="Cliemail" obligatorio="" colsm="6" colmd="6" onChange={setCliemail}/>        
                                                                        <InputCheckBox value={Estadocliente} campo="" name="Estadocliente" obligatorio="" colsm="6" colmd="6" mensaje="¿Cliente activo?" onChange={setEstadocliente}/> 
                                                                        <InputAreaText value={Cliobs} campo="Observaciones" name="Cliobs" obligatorio="" colsm="12" onChange={setCliobs}/>  
                                                                    </div>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion> */}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* CALCULO */}
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 p-4">
                                                {/* Titulo */}
                                                <div className="row mt-2">
                                                    <div className="col-12">
                                                        <h5 className="card-title text-success">Datos del alojamiento</h5>
                                                        <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                                                    </div>
                                                </div>
                                                {/* Datos */}
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <InputComboBoxDuracionAdd campo="Duracion" name="Txtduracion" obligatorio="*" colsm="12" colmd="6" url={url} onChange={setTxtduracion}/> {/* <<<<<< CAMBIO */}
                                                            {/* <InputTextCantidad campo="Cantidad" name="Cantidad" obligatorio="*" colsm="12" colmd="6" onChange={setCantidad}/> */}
                                                            <div className={`col-12 col-sm-12 col-md-6 mb-3`}>
                                                                <label htmlFor={`txtCantidad`} className="form-label">Cantidad<span className="text-danger">*</span></label>
                                                                <div className="input-group">
                                                                    <button className="btn btn-outline-success" type="button" id="button-addon1" onClick={() => {
                                                                        if (Cantidad > 0) {
                                                                        setCantidad((prevCantidad) => prevCantidad - 1);
                                                                        }
                                                                    }}>
                                                                    <i className="fa-solid fa-minus"></i>
                                                                    </button>
                                                                    <input id={`txtCantidad`} className="form-control" value={Cantidad}
                                                                    onChange={(e) => {
                                                                        const newValue = parseInt(e.target.value, 10) || 0;
                                                                        setCantidad(newValue);
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        const newValue = parseInt(e.target.value, 10) || 0;
                                                                        setCantidad(newValue);
                                                                    }}
                                                                    />
                                                                    <button className="btn btn-outline-success" type="button" id="button-addon1"
                                                                    onClick={() => {
                                                                        setCantidad((prevCantidad) => prevCantidad + 1);
                                                                    }}
                                                                    >
                                                                    <i className="fa-solid fa-plus"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className={`col-12 col-sm-6 col-md-6 mb-3`}>
                                                                <Link onClick={FnCalcularAlquiler} className="col-12 col-sm-6 col-md-6 mb-2">
                                                                    <button className="btn btn-outline-success din-btn-style w-100"><i className="fa-solid fa-wallet"></i>&nbsp;&nbsp;Calcular</button>
                                                                </Link>
                                                            </div>
                                                            <div className={`col-12 col-sm-6 col-md-6 mb-3`}>
                                                                <Link className="col-12 col-sm-6 col-md-6 mb-2">
                                                                    <button className="btn btn-outline-success din-btn-style w-100" onClick={() => handleShowDetalleTarifa()}><i className="fa-solid fa-book-open"></i>&nbsp;&nbsp;Detalle</button>
                                                                </Link>
                                                            </div>
                                                            <InputTextMonto value={Monto} campo="Monto" name="Monto" obligatorio="*" colsm="6" colmd="6" onChange={setMonto}/>        {/* <<<<<< CAMBIO */}
                                                            <InputTextAdelanto value={Adelanto} campo="Adelanto" name="Adelanto" obligatorio="*" colsm="6" colmd="6" onChange={setAdelanto}/>        {/* <<<<<< CAMBIO */}
                                                            <InputDateFechaFin value={AlojCheckoutDate} campo="Fecha Fin" name="FechaFin" obligatorio="*" colsm="6" colmd="6" onChange={setAlojCheckoutDate} disabled="disabled"/>         {/* <<<<<< CAMBIO */}
                                                            <InputTimeHoraFin value={AlojCheckoutTime} campo="Hora Fin" name="HoraFin" obligatorio="*" colsm="6" colmd="6" onChange={setAlojCheckoutTime} disabled="disabled"/>         {/* <<<<<< CAMBIO */}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Titulo */}
                                                <div className="row mt-2">
                                                    <div className="col-12">
                                                        <h5 className="card-title text-success">Incidentes</h5>
                                                        <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                                                    </div>
                                                </div>
                                                {/* Datos */}
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="row">
                                                            {/* Buscador incidentes */}
                                                            <div className={`col-12`}>
                                                                <div className="row">
                                                                    <label className="col-3 col-form-label">Numero de documento</label>
                                                                    <div className="col-9 d-flex align-items-center">
                                                                        <div className="input-group">
                                                                            <input className="form-control" onChange={(e) => {setSearchIncidente(e.target.value);}}/>
                                                                            <button className="btn btn-outline-success" onClick={handleSearchIncidente}><i className="fa-solid fa-search"></i></button>
                                                                        </div>
                                                                    </div>  
                                                                </div>
                                                                <div className="row">
                                                                    {DataIncidentes.length > 0 ? (
                                                                        DataIncidentes.map((dato, index) => (
                                                                            <div className='col-12 px-4'  key={index} >
                                                                                <div className='row mt-2 mb-2' style={{ borderTop: '1px solid #2FAC68'}}></div>
                                                                                <Link onClick={() => handleShowDetalle(dato.idalquiler)} className='text-black' style={{textDecoration:'none'}}>
                                                                                    <div className='row mt-2'>
                                                                                        <div className='col-10 col-sm-10 col-md-10 col-lg-10'>
                                                                                            <div className='row'>
                                                                                                <div className='col-12'>
                                                                                                    <span style={{ fontWeight: '600' }}>#{dato.idalquiler}</span>
                                                                                                    &nbsp;&nbsp;<span className="texto-xs badge bg-danger">{dato.txttipoincidente}</span>
                                                                                                    &nbsp;&nbsp;<span className="texto-mediano">{dato.fechact}</span>
                                                                                                </div>
                                                                                                <div className='col-12 texto-lg'>
                                                                                                    {dato.incidente}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{ fontWeight: '600' }} className='col-2 col-sm-2 col-md-2 col-lg-2 d-flex align-items-center'>
                                                                                            <i className="fa-solid fa-circle-exclamation fa-lg text-warning"></i>
                                                                                        </div>
                                                                                    </div>
                                                                                </Link>
                                                                            </div>
                                                                            ))
                                                                        ) : (
                                                                        <div className='col-12 px-4'>
                                                                            <div className="row">
                                                                                <div className='mt-2 mb-2' style={{ borderTop: '1px solid #2FAC68'}}></div>
                                                                                <div className='text-center mt-2'>No hay incidentes encontrados</div>
                                                                            </div>
                                                                        </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        {/* Botones */}
                                        <div className="row">
                                            <div className="col-12">
                                                <div className='row p-1 d-flex justify-content-end'>
                                                    <Link onClick={FnProcesarCheckin} className="col-12 col-sm-6 col-md-6 mb-2">
                                                        <button className="btn btn-success din-btn-style w-100"><i className="fa-solid fa-check"></i>&nbsp;&nbsp;Procesar Checkin</button>
                                                    </Link>
                                                    <Link to={`/${nombre_controller}List`} className="col-12 col-sm-6 col-md-6 mb-2">
                                                        <button className="btn btn-secondary din-btn-style w-100"><i className="fa-solid fa-arrow-right-from-bracket"></i>&nbsp;&nbsp;Ir a Recepcion</button>
                                                    </Link>
                                                </div>   
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            
                            
                        </div>
                    </div>
                </div>
                {/* Modal Detalle Tarifa */}
                <>
                    <Modal show={ShowDetalleTarifa} onHide={CloseDetalleTarifa} >
                        <Modal.Header closeButton style={{backgroundColor:'#198754', color:'White'}}>
                            <Modal.Title>Calcular Tarifa</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-12">
                                    <table className="table table-sm table-striped table-hover table-bordered">
                                        <thead><tr><th>Fecha</th><th>Tarifa</th><th></th></tr></thead>
                                        <tbody>
                                            {DataDetalleTarifa.map((detalle, index) => (
                                                <tr key={index}>
                                                    <td className="text-center" style={{verticalAlign: 'middle', width:'40%'}}>
                                                        {detalle.fechaFormateada}
                                                        <input className="form-control" name={`in_fecha${index+1}`} id={`in_fecha`} type="hidden" value={detalle.fecha}/>
                                                    </td>
                                                    <td style={{width:'50%'}}>
                                                        <div className="input-group">
                                                            <button className="btn btn-outline-success" style={{display: showButton ? 'block' : 'none'}} onClick={() => reducirTarifa(index)}>
                                                                <i className="fa-solid fa-minus"></i>
                                                            </button>
                                                            <input className="form-control" name={`in_tarifa${index+1}`} id={`in_tarifa`} value={detalle.tarifa} 
                                                            onChange={(e) => handleInputChange(e, index)} {...(!showButton && { readOnly: true })}/>
                                                            <button className="btn btn-outline-success" style={{display: showButton ? 'block' : 'none'}} onClick={() => aumentarTarifa(index)}>
                                                                <i className="fa-solid fa-plus"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td className="text-center" style={{verticalAlign: 'middle',width:'10%' }} >
                                                        {index === DataDetalleTarifa.length - 1 && index > 0 ? (
                                                            <Link onClick={() => FnDeleteCantidadTarifa(index)}>
                                                                <i className="fa-solid fa-trash text-danger"></i>
                                                            </Link>
                                                        ) : (
                                                            <span>
                                                                <i className="fa-solid fa-trash text-secondary"></i>
                                                            </span>
                                                        )}
                                                    </td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <hr className="border-2 border-success" />
                                    <ul style={{listStyle:'none'}} className="p-0 mb-0">
                                        <li style={{display: showButton ? 'none' : 'block'}}><b>Costo del Servicio: </b>{Monto}</li>
                                        <li><b>Fecha Inicio: </b> {AlojCheckinFormat}</li>
                                        <li><b>Fecha Fin: </b> {AlojCheckoutFormat}</li>
                                    </ul>
                                </div>

                                {/* {DataDetalleTarifa.map((detalle, index) => (
                                    <div className={`col-12 col-sm-6 col-md-6 mb-3`} key={index}>
                                        <label className="form-label">Detalle {detalle[2]}</label>
                                        <input className="form-control" name={`in_fecha${index+1}`} id={`in_fecha`} type="hidden" value={detalle[0]} />
                                        <input className="form-control" name={`in_tarifa${index+1}`} id={`in_tarifa`} value={detalle[1]} />
                                    </div>
                                ))} */}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={FnOkCalcularTarifa}>Aceptar</Button>
                            <Button variant="secondary" onClick={CloseDetalleTarifa}>Cerrar</Button>
                        </Modal.Footer>
                    </Modal>
                </>
                {/* Modal Detalle*/}
                <Modal show={showDetalle} onHide={CloseDetalle} size="lg">
                    <Modal.Header className='bg-success text-white' closeButton><Modal.Title >Detalle</Modal.Title></Modal.Header>
                    <Modal.Body className='px-1' id='TabsCaja'>
                        <Tabs id="controlled-tab-example" defaultActiveKey="Alquiler" className="mb-3" >
                            <Tab eventKey="Alquiler" title="Alquiler">
                            <div className="m-3">
                                {/* CARD BODY */}
                                <div className="mx-2">
                                    <div className="row">
                                    <div className="col-12">
                                        <div className='row'>
                                            <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Numero de Alquiler:&nbsp;&nbsp;</div>
                                            <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{dataDetalleAlquiler.idalquiler}</div>
                                            <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Numero de Habitacion:&nbsp;&nbsp;</div>
                                            <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{dataDetalleAlquiler.numero}</div>
                                            <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Detalle:&nbsp;&nbsp;</div>
                                            <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{dataDetalleAlquiler.detalle}</div>
                                            <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Nivel:&nbsp;&nbsp;</div>
                                            <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{dataDetalleAlquiler.nivel}</div>
                                            <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Monto de Alojamiento:&nbsp;&nbsp;</div>
                                            <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{dataDetalleAlquiler.moneda} {dataDetalleAlquiler.aloj_monto_alquiler ? parseFloat(dataDetalleAlquiler.aloj_monto_alquiler).toFixed(2) : "0.00"}</div>
                                            <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Monto de Servicios:&nbsp;&nbsp;</div>
                                            <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{dataDetalleAlquiler.moneda} {dataDetalleAlquiler.aloj_monto_servicio ? parseFloat(dataDetalleAlquiler.aloj_monto_servicio).toFixed(2) : "0.00"}</div>
                                            <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Monto por Cobrar:&nbsp;&nbsp;</div>
                                            <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{dataDetalleAlquiler.moneda} {dataDetalleAlquiler.aloj_monto_cobrar ? parseFloat(dataDetalleAlquiler.aloj_monto_cobrar).toFixed(2) : "0.00"}</div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            </Tab>
                            <Tab eventKey="Huespedes" title="Huespedes">
                            <div className='m-3'>
                                <div className='mx-2'>
                                <div className='row'>
                                    {DataDetalleHuespedes.map((data, index) => (
                                        <div className="col-12 mb-3" key={index}>
                                            <Card border={data.idcliente === dataDetalleAlquiler.idhuesped ? "success" : undefined} >
                                                <Card.Header className={`p-2 text-center ${data.idcliente === dataDetalleAlquiler.idhuesped ? 'bg-success' : ''}`}>
                                                    <h1 className={`m-0 ${data.idcliente === dataDetalleAlquiler.idhuesped ? 'text-white' : ''}`}>
                                                        #{index+1}: CLIENTE {data.idcliente === dataDetalleAlquiler.idhuesped ? 'PRINCIPAL' : ''}
                                                    </h1>
                                                </Card.Header>
                                                <Card.Body>
                                                    <div className='row'>
                                                        <div className='col-10'>
                                                            <div className='row'>
                                                                <div className='col-12' style={{ textTransform: 'uppercase' }}>
                                                                    <span style={{fontWeight:'500'}}>{data.clinom}</span> - {data.txttipodocumento}: {data.cliruc}
                                                                </div>
                                                                <div className='col-12' style={{ textTransform: 'uppercase' }}>
                                                                    DIRECCION: {data.clidirec} - TELF. {data.clitelf ? data.clitelf : "-"}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-2 text-end'>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    ))}
                                </div>
                                </div>
                            </div>
                            </Tab>
                            <Tab eventKey="Incidente" title="Incidente">
                            <div className='m-3'>
                                <div className='mx-2'>
                                <div className='row'>
                                    <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Incidente:&nbsp;&nbsp;</div>
                                        <div className='col-7 col-sm-7 col-md-7 col-lg-9'>
                                            <input className="form-check-input" type="checkbox" checked={dataDetalleAlquiler.isincidente === 1}/>
                                        </div>
                                        <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Tipo:&nbsp;&nbsp;</div>
                                        <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{dataDetalleAlquiler.txttipoincidente ? dataDetalleAlquiler.txttipoincidente : "-"}</div>
                                        <div className='col-5 col-sm-5 col-md-5 col-lg-3' style={{ textAlign: 'right', fontWeight: '500' }}>Descripcion:&nbsp;&nbsp;</div>
                                        <div className='col-7 col-sm-7 col-md-7 col-lg-9'>{dataDetalleAlquiler.incidente ? dataDetalleAlquiler.incidente : "-"}</div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </Tab>
                        </Tabs>
                    </Modal.Body>
                    <Modal.Footer>
                    <button className="btn btn-secondary din-btn-style" onClick={CloseDetalle}>
                        <i className="fa-solid fa-xmark"></i>&nbsp;&nbsp;Cerrar
                    </button>
                    </Modal.Footer>
                </Modal>
                <Toast show={showToast} onClose={() => setShowToast(false)} delay={4000} autohide className='text-white' 
                    style={{ position: 'fixed', top: '20px',right: '20px', zIndex: 1100, background: `${toastBackground}`,}}>
                    <Toast.Header>
                        <strong className="me-auto">Notificación</strong>
                        <small></small>
                    </Toast.Header>
                    <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>
                <FooterView/>
            </animated.div>
        </>
    );
}

async function UbicarNiveles(id, setters) {
    try {
        const response = await fetch(`${url}/ComboBox/UbigeoReturn/${id}`, {
            method: 'POST',
            credentials: 'include',
        });
        if (response.ok) {
            const data = await response.json();
            if(data.res){
                const datos = data.datos;
                setters.setIddepartamento(datos.id3);/* <<<<<< CAMBIO */
                setters.setIdprovincia(datos.id2);  /* <<<<<< CAMBIO */
                setters.setIddistrito(datos.id1);   /* <<<<<< CAMBIO */
                return datos;
            }
        }else{
            throw new Error("Error en la respuesta del servidor");
        }
    } catch (error) {
      throw error;
    }
}

export default AlquilerAdd;    /* <<<<<< CAMBIO */

